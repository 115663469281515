/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import { Button, ConnectLogo } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import { route } from '../../routes';

function Header() {
  const navigate = useNavigate();

  const [_onChange, _temporaryFormValues, setTemporaryFormValues] =
    useFormStorage(REQUEST_QUOTE_FORM_KEY);
  const onClickClear = useCallback(() => {
    setTemporaryFormValues({});
    navigate(route('welcome'));
  }, []);

  return (
    <header className="border-gray-100 top-0 z-200 bg-white border-b md:fixed md:w-full min-h-48 max-h-48">
      <div className="flex flex-wrap items-center justify-between 2xl:container 2xl:mx-auto py-2 px-4">
        <div className="order-1">
          <ConnectLogo />
        </div>
        <nav className="order-2 2xl:order-2 2xl:w-auto self-end print:hidden">
          <Button
            onClick={onClickClear}
            arrangement="leading-icon"
            icon="plus-circle"
            variant="secondary"
          >
            New location
          </Button>
        </nav>
      </div>
    </header>
  );
}

export default Header;
