import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { Badge } from '../../components/Badge/Badge';
import { TextInput } from '../../components/TextInput/TextInput';
import { Button } from '../../controls/Button/Button';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { Subheading } from '../../text/Subheading';
import { isDefined } from '../../utilities/isDefined';
import { space } from '../../utilities/shared/sizes';
import { usePrevious } from '../../utilities/usePrevious';

const RackHeaderHeading = styled(Subheading, {
  flex: '1 1 auto',
  padding: 2,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const RackHeaderTextInputContainer = styled(RackHeaderHeading, {
  marginLeft: -8,
});

const RackHeaderContainer = styled('div', {
  display: 'flex',
  gap: '$6',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$10 $12 $10 $16',
  strokeBottom: colors.strokeNeutralLight,
  borderRadius: '$8 $8 0 0',
  [darkThemeSelector]: {
    strokeBottom: colors.strokeNeutralDark,
  },

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '$44',
  },
});

type RackHeaderProps = {
  heading: string;
  mounts: number;
  onUpdate?: (values: { heading: string; mounts: number }) => void;
  onDelete?: () => void;
  onClickAddNote?: () => void;
  isUpdating?: boolean;
};

const EditableBody = styled(Body, {
  display: 'inline-block',
  width: '100%',
  padding: '4px 0',
});

function RackHeader({
  heading,
  mounts,
  onDelete,
  onUpdate,
  onClickAddNote,
  isUpdating,
}: RackHeaderProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [isMouseIn, setMouseIn] = useState(false);
  const [editing, setEditing] = useState(false);
  const showInput = isMouseIn || editing;
  const prevShowInput = usePrevious(showInput);

  const [updatedHeading, setUpdatedHeading] = useState(heading);
  const [updatedMounts, setUpdatedMounts] = useState(mounts);

  const handleMouseOver = useCallback(() => {
    setMouseIn(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMouseIn(false);
  }, []);

  const handleSubmit = useCallback(() => {
    onUpdate?.({ heading: updatedHeading, mounts: updatedMounts });
  }, [onUpdate, updatedHeading, updatedMounts]);

  const reset = useCallback(() => {
    setUpdatedHeading(heading);
    setUpdatedMounts(mounts);
  }, [heading, mounts]);

  useEffect(() => {
    if (!showInput && prevShowInput) {
      reset();
    }
  }, [showInput, prevShowInput, reset]);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') handleSubmit();
      if (e.code === 'Escape') {
        (e.target as HTMLInputElement).blur();
        reset();
      }
    },
    [handleSubmit, reset],
  );

  return (
    <RackHeaderContainer ref={ref} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {onUpdate && showInput ? (
        <>
          <RackHeaderTextInputContainer>
            <TextInput
              aria-label="Heading"
              onChange={(value) => {
                setUpdatedHeading(value);
              }}
              onKeyUp={handleKeyUp}
              onFocus={() => {
                setEditing(true);
              }}
              onBlur={() => {
                setEditing(false);
              }}
              value={updatedHeading}
            />
          </RackHeaderTextInputContainer>
          <TextInput
            type="number"
            aria-label="Mounts"
            width="60px"
            value={updatedMounts.toString()}
            onChange={(value) => {
              setUpdatedMounts(Number.parseInt(value, 10));
            }}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setEditing(true);
            }}
            onBlur={() => {
              setEditing(false);
            }}
          />
          {onClickAddNote && (
            <Button
              variant="secondary"
              onClick={onClickAddNote}
              icon="document"
              arrangement="hidden-label"
              title="Add note"
            >
              Add note
            </Button>
          )}
          {onDelete && (
            <Button
              variant="destructive"
              onClick={onDelete}
              icon="trash-can"
              arrangement="hidden-label"
              title="Delete rack"
              disabled={isUpdating}
            >
              Delete rack
            </Button>
          )}
          <Button
            variant="primary"
            onClick={handleSubmit}
            icon="checkmark"
            arrangement="hidden-label"
            title="Save rack"
            disabled={isUpdating}
          >
            Save rack
          </Button>
        </>
      ) : (
        <>
          <RackHeaderHeading>
            <EditableBody>{heading}</EditableBody>
          </RackHeaderHeading>
          <Badge size="small" variant="neutral">
            {mounts}U
          </Badge>
        </>
      )}
    </RackHeaderContainer>
  );
}

const RackDeviceIcon = styled(Icon, {
  variants: {
    active: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackDeviceLabel = styled(Small, {
  fontWeight: '$bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackDeviceStart = styled('div', {
  minWidth: 0,
  hStack: '$4',
});

const RackDeviceEnd = styled('div', {
  hStack: '$4',
});

const RackDeviceContainer = styled('div', FocusRing, {
  hStack: '$6',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 0,
  margin: '$2',
  padding: '0 $6',
  borderRadius: '$8',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    // variant styles in compoundVariants below
    active: {
      true: {},
      false: {},
    },
    empty: {
      true: {
        border: `2px dashed ${colors.strokeNeutralLight}`,
        [darkThemeSelector]: {
          borderColor: colors.strokeNeutralDark,
        },
      },
      false: {
        background: colors.bgApplicationLight,
        strokeAll: colors.strokeNeutralLight,
        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          strokeAll: colors.strokeNeutralDark,
        },
      },
    },
  },
  compoundVariants: [
    {
      active: true,
      empty: true,
      css: {
        strokeAll: 'none',
        boxShadow: 'none',
        border: `2px dashed ${colors.strokeBrandLight}`,
        background: colors.bgBrandLight,
        [darkThemeSelector]: {
          borderColor: colors.strokeBrandDark,
          background: colors.bgBrandDark,
        },
      },
    },
    {
      active: true,
      empty: false,
      css: {
        strokeAll: colors.strokeBrandLight,
        background: colors.bgBrandLight,
        [darkThemeSelector]: {
          strokeAll: colors.strokeBrandDark,
          background: colors.bgBrandDark,
        },
      },
    },
  ],
  defaultVariants: {
    active: false,
    empty: false,
  },
});

export type RackDeviceType =
  | 'security-appliance'
  | 'access-point'
  | 'switch'
  | 'cable-management'
  | 'fiber'
  | 'isp'
  | 'patch-panel'
  | 'ups'
  | 'other';

export type RackDeviceManufacturer = '3rd-party' | 'meter';

interface DeviceInfo {
  ports?: number;
}

interface RackDeviceProps {
  active?: boolean;
  label?: string;
  manufacturer?: RackDeviceManufacturer;
  deviceInfo?: DeviceInfo;
  onClick?: (event: any) => void;
  type?: RackDeviceType;
  emptyPlaceholder?: React.ReactNode;
}

const typeLabel = (
  type: RackDeviceType,
  manufacturer?: '3rd-party' | 'meter',
  includeManufacturer = false,
) => {
  switch (type) {
    case 'security-appliance':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter security appliance';
      }
      return 'Security appliance';
    case 'access-point':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter access point';
      }
      return 'Access point';
    case 'cable-management':
      return 'Cable management';
    case 'fiber':
      return 'Fiber';
    case 'isp':
      return 'ISP';
    case 'patch-panel':
      return 'Patch panel';
    case 'switch':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter switch';
      }
      return 'Switch';
    case 'ups':
      return 'UPS';
    case 'other':
    default:
      return 'Other';
  }
};

function RackDevice({
  active,
  label,
  manufacturer,
  onClick,
  type,
  emptyPlaceholder,
  deviceInfo,
}: RackDeviceProps) {
  const meterDevice =
    manufacturer === 'meter' &&
    (type === 'security-appliance' || type === 'switch' || type === 'access-point');

  if (!type) {
    return (
      <RackDeviceContainer empty clickable={isDefined(onClick)} onClick={onClick} active={active}>
        {emptyPlaceholder}
      </RackDeviceContainer>
    );
  }

  return (
    <RackDeviceContainer clickable={isDefined(onClick)} onClick={onClick} active={active}>
      <RackDeviceStart>
        {meterDevice && <RackDeviceIcon active={active} icon={type} size={space(16)} />}
        {(label || type) && (
          <RackDeviceLabel active={active}>
            {label || typeLabel(type, manufacturer, true)}
          </RackDeviceLabel>
        )}
      </RackDeviceStart>
      <RackDeviceEnd>
        {deviceInfo?.ports != null && (
          <Badge size="small" variant={active ? 'brand' : 'neutral'}>
            {deviceInfo.ports} ports
          </Badge>
        )}
        {type && (
          <Badge size="small" variant={active ? 'brand' : 'neutral'}>
            {typeLabel(type, manufacturer, false)}
          </Badge>
        )}
        {meterDevice && (
          <Body>
            <Icon icon={active ? 'cross' : 'chevron-right'} size={space(8)} />
          </Body>
        )}
      </RackDeviceEnd>
    </RackDeviceContainer>
  );
}

const RackMountIndexNumber = styled(Small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const RackMountIndexDivider = styled('div', {
  display: 'flex',
  width: '$2',
  height: '100%',

  variants: {
    active: {
      true: {
        backgroundColor: colors.strokeBrandLight,
        [darkThemeSelector]: {
          backgroundColor: colors.strokeBrandDark,
        },
      },
      false: {
        backgroundColor: colors.strokeNeutralLight,
        [darkThemeSelector]: {
          backgroundColor: colors.strokeNeutralDark,
        },
      },
    },
  },
});

const RackMountIndexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  paddingY: '$8',
  alignItems: 'center',
  width: '46px',
  height: '100%',
  textAlign: 'center',
});

const RackMountContainer = styled('div', {
  display: 'flex',
  flex: '0 1 auto',
  width: '100%',
  minHeight: '32px',
});

function RackMountIndex({
  last,
  first,
  active = false,
}: {
  last: number;
  first?: number;
  active?: boolean;
}) {
  if (!first || last === first) {
    return (
      <RackMountIndexContainer>
        <RackMountIndexNumber family="monospace">{first}</RackMountIndexNumber>
      </RackMountIndexContainer>
    );
  }

  return (
    <RackMountIndexContainer>
      <RackMountIndexNumber family="monospace">{first}</RackMountIndexNumber>
      <RackMountIndexDivider active={active} />
      <RackMountIndexNumber family="monospace">{last}</RackMountIndexNumber>
    </RackMountIndexContainer>
  );
}

type RackMountProps = RackDeviceProps & {
  first: number;
  last: number;
};

const MOUNT_DEFAULT = 32;

function mountHeights(first: number, last: number) {
  const mountLast = last;
  const mountDiff = first - mountLast + 1;
  const mountHeight = mountDiff * MOUNT_DEFAULT;
  const mountMinHeight = mountDiff >= 2 ? MOUNT_DEFAULT * 2 : MOUNT_DEFAULT;

  return {
    height: first !== 1 ? mountHeight : MOUNT_DEFAULT,
    minHeight: first !== 1 ? mountMinHeight : MOUNT_DEFAULT,
  };
}

export function RackMount({
  active,
  first,
  label,
  last,
  manufacturer,
  onClick,
  type,
  emptyPlaceholder,
  deviceInfo,
}: RackMountProps) {
  const heights = mountHeights(first, last);

  return (
    <RackMountContainer css={heights}>
      <RackMountIndex last={last} first={first} />
      <RackDevice
        active={active}
        label={label}
        manufacturer={manufacturer}
        deviceInfo={deviceInfo}
        onClick={onClick}
        type={type}
        emptyPlaceholder={emptyPlaceholder}
      />
      <RackMountIndex last={last} first={first} />
    </RackMountContainer>
  );
}

const RackContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  minHeight: 0,
  overflowY: 'auto',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeApplicationLight,
  borderRadius: '0 0 $8 $8',
  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeApplicationDark,
  },

  '@maxSm': {
    width: '100%',
  },
});

const RackMounts = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  minHeight: 0,
  padding: '$8 0',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeApplicationLight,
  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeApplicationDark,
  },

  '@sm': {
    width: '400px',
    maxWidth: '400px',
    minWidth: '400px',
  },
});

export const RackContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  minHeight: 0,
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  strokeAll: colors.strokeApplicationLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: colors.strokeApplicationDark,
  },

  '@maxSm': {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    scrollSnapAlign: 'start',
  },
  '@sm': {
    flex: '0 0 auto',
  },
});

type RackNoteProps = {
  first: number;
  last: number;
  note: string | null;
  active?: boolean;
  onClick?: () => void;
};

type RackProps = RackHeaderProps & {
  notes?: RackNoteProps[];
  children: React.ReactNode;
};

const RackNotePlaceholder = styled('div', {
  flex: '0 1 auto',
});

const RackNoteContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',
  flex: '0 1 auto',

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    active: {
      true: {
        background: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,
        [darkThemeSelector]: {
          background: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
      false: {
        background: colors.bgApplicationLight,
        strokeAll: colors.strokeApplicationLight,
        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          strokeAll: colors.strokeApplicationDark,
        },
      },
    },
  },
});

const RackNoteBody = styled(Body, {
  display: 'block',
  width: '100%',
  maxHeight: '100%',
  overflowY: 'auto',
  padding: '$6 $8 $6 0',
});

export function RackNote({ first, last, note, onClick, active = false }: RackNoteProps) {
  const heights = mountHeights(first, last);

  if (note == null) {
    return <RackNotePlaceholder css={heights} />;
  }

  return (
    <RackNoteContainer css={heights} active={active} clickable={!!onClick} onClick={onClick}>
      <RackMountIndex first={first} last={last} active={active} />
      <RackNoteBody>{note}</RackNoteBody>
    </RackNoteContainer>
  );
}

export type IndexedItem<T> = {
  first: number;
  last: number;
  item: T | null;
};

export const continuousRackElevationSegments = <T,>(
  items: IndexedItem<T>[],
  mounts: number,
): IndexedItem<T>[] => {
  if (items.length === 0) {
    return [
      {
        first: mounts,
        last: 1,
        item: null,
      },
    ];
  }

  const list = items.slice();

  // Rack elevation indices are displayed highest on top -> lowest on bottom
  list.sort((a, b) => b.last - a.last);

  // Fill gaps between devices with placeholder null devices to simplify rendering
  for (let i = 0; i < list.length - 1; i += 1) {
    if (list[i].last > list[i + 1].first + 1) {
      list.splice(i + 1, 0, {
        last: list[i + 1].first + 1,
        first: list[i].last - 1,
        item: null,
      });
      i += 1;
    }
  }

  // Add placeholder device at top (high end) if needed
  if (list[0].first < mounts) {
    list.splice(0, 0, {
      last: list[0].first + 1,
      first: mounts,
      item: null,
    });
  }

  // Add placeholder device on bottom (low end) if needed
  if (list[list.length - 1].last > 1) {
    list.push({
      last: 1,
      first: list[list.length - 1].last - 1,
      item: null,
    });
  }

  return list;
};

const RackNotesContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '180px',
  minWidth: '180px',
  maxWidth: '180px',
  height: '100%',
  minHeight: 0,
  padding: '$8 0',
});

export function RackNotes({ mounts, notes }: { mounts: number; notes: RackNoteProps[] }) {
  const filledNotes = continuousRackElevationSegments(
    notes.map((n) => ({ ...n, item: n })),
    mounts,
  );

  return (
    <RackNotesContainer>
      {filledNotes.map((note) => (
        <RackNote
          key={`note-${note.first}-${note.last}`}
          first={note.first}
          last={note.last}
          note={note.item?.note ?? null}
          active={note.item?.active}
          onClick={note.item?.onClick}
        />
      ))}
    </RackNotesContainer>
  );
}

export function Rack({
  children,
  heading,
  mounts,
  notes,
  onUpdate,
  onDelete,
  onClickAddNote,
  isUpdating,
}: RackProps) {
  return (
    <RackContainer>
      <RackHeader
        heading={heading}
        mounts={mounts}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onClickAddNote={onClickAddNote}
        isUpdating={isUpdating}
      />
      <RackContent>
        <RackMounts>{children}</RackMounts>
        {notes && <RackNotes mounts={mounts} notes={notes} />}
      </RackContent>
    </RackContainer>
  );
}

export const Racks = styled('div', {
  hStack: '$20',
  alignItems: 'start',
  minHeight: 0,
});
