/* eslint-disable no-empty */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Helper to parse and store form values in localstorage, before they are
// submitted
import { useCallback } from 'react';

import { useLocalStorage } from '@meterup/common';

// Use this key when pertaining to the request quote form flow
export const REQUEST_QUOTE_FORM_KEY = 'requestQuoteFormValues';

export default function useFormStorage(key: string) {
  const namespace = 'connect.meter.com';
  const localStorageKey = `${namespace}/temporaryFormStorage${key}/`;
  const [temporaryFormValues, setTemporaryFormValues] = useLocalStorage<any>(localStorageKey, {});

  // TODO: This doens't support an empty to empty value (due to missing key)
  const onChange = useCallback(
    ({ values }) => {
      if (
        JSON.stringify(temporaryFormValues) !==
        JSON.stringify({ ...temporaryFormValues, ...values })
      ) {
        setTemporaryFormValues({ ...temporaryFormValues, ...values });
      }
    },
    [temporaryFormValues],
  );

  return [onChange, temporaryFormValues, setTemporaryFormValues];
}
