import type {
  DropdownMenuCheckboxItemProps,
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuRadioItemProps,
  DropdownMenuSubTriggerProps,
} from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React, { createContext, forwardRef, useContext, useEffect, useMemo, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ButtonProps } from '../../controls/Button/Button';
import { Icon } from '../../assets/Icon/Icon';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../common/animations';
import {
  contentStyles,
  itemIndicatorStyles,
  itemStyles,
  labelStyles,
  separatorStyles,
} from '../../common/menus';
import { Button } from '../../controls/Button/Button';
import { colors, darkThemeSelector, DIALOG_CONTENT_ZINDEX, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  ...contentStyles,
  padding: '$4',
  minWidth: 180,
  maxHeight: '80vh',
  overflow: 'auto',
  // necessary to prevent select menus in dialogs from appearing behind the dialog content
  zIndex: DIALOG_CONTENT_ZINDEX + 1,
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideRightAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideLeftAndFade },
    },
  },
});

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
});

const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
});

const StyledSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  '&[data-state="open"]:not(:focus)': {
    backgroundColor: colors.brand50,
    color: colors.brand800,
    [darkThemeSelector]: {
      backgroundColor: colors.brand900,
      color: colors.brand50,
    },
  },
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, Small, labelStyles);

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, separatorStyles);

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, itemIndicatorStyles);

const StyledItemIcon = styled('div', itemIndicatorStyles);

const RightSlot = styled('div', {
  display: 'flex',
  marginLeft: 'auto',
  paddingLeft: '$6',
  color: colors.gray600,
  [darkThemeSelector]: {
    color: colors.gray300,
  },
  ':focus > &, [data-state="open"] > &': {
    color: 'inherit',
  },
  '[data-disabled] &': {
    color: colors.gray500,
  },
});

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuIconContext = createContext<{
  incrementIcons: () => void;
  decrementIcons: () => void;
}>(null as any);

const useDropdownMenuIconContext = () => useContext(DropdownMenuIconContext);

export const DropdownMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, align, ...props }, forwardedRef) => {
    const [iconCount, setIconCount] = useState<number>(0);

    const ctx = useMemo(
      () => ({
        incrementIcons: () => setIconCount((p) => p + 1),
        decrementIcons: () => setIconCount((p) => p - 1),
      }),
      [setIconCount],
    );

    return (
      <DropdownMenuIconContext.Provider value={ctx}>
        <DropdownMenuPrimitive.Portal>
          <StyledContent
            data-hasicons={iconCount > 0}
            align={align}
            sideOffset={4}
            {...props}
            ref={forwardedRef}
          >
            {children}
          </StyledContent>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuIconContext.Provider>
    );
  },
);

export const DropdownMenuSubMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <DropdownMenuContent sideOffset={2} alignOffset={-4} {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuContent>
  ),
);

export const DropdownMenuTriggerButton = forwardRef<HTMLButtonElement, ButtonProps>(
  // eslint-disable-next-line react/prop-types
  ({ menuArrow = 'dropdown', ...props }, forwardedRef) => (
    <DropdownMenuTrigger asChild>
      <Button
        {...props}
        ref={forwardedRef}
        variant="secondary"
        size="small"
        menuArrow={menuArrow}
      />
    </DropdownMenuTrigger>
  ),
);

interface DropdownMenuItemSharedProps {
  rightContent?: ReactNode;
  internal?: boolean;
}

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps & DropdownMenuItemSharedProps & { icon?: IconName }
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, icon, internal, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();

    useEffect(() => {
      if (icon) {
        ctx.incrementIcons();
        return () => ctx.decrementIcons();
      }

      return () => {};
    }, [ctx, icon]);

    return (
      <StyledItem {...props} internal={internal} ref={forwardedRef}>
        {icon && (
          <StyledItemIcon>
            <Icon icon={icon} size={space(16)} />
          </StyledItemIcon>
        )}
        {children}
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledItem>
    );
  },
);

export function DropdownMenuSubMenuIndicator() {
  return (
    <RightSlot>
      <Icon icon="chevron-right" size={space(10)} />
    </RightSlot>
  );
}

export const DropdownMenuSubTrigger = forwardRef<HTMLDivElement, DropdownMenuSubTriggerProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <StyledSubTrigger {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuSubMenuIndicator />
    </StyledSubTrigger>
  ),
);

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  DropdownMenuCheckboxItemProps & DropdownMenuItemSharedProps
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();
    useEffect(() => {
      ctx.incrementIcons();
      return () => ctx.decrementIcons();
    }, [ctx]);
    return (
      <StyledCheckboxItem {...props} ref={forwardedRef}>
        {children}
        <DropdownMenuItemIndicator>
          <Icon icon="checkmark" size={space(16)} />
        </DropdownMenuItemIndicator>
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledCheckboxItem>
    );
  },
);

export const DropdownMenuRadioItem = forwardRef<
  HTMLDivElement,
  DropdownMenuRadioItemProps & DropdownMenuItemSharedProps
>(
  // eslint-disable-next-line react/prop-types
  ({ children, rightContent, ...props }, forwardedRef) => {
    const ctx = useDropdownMenuIconContext();
    useEffect(() => {
      ctx.incrementIcons();
      return () => ctx.decrementIcons();
    }, [ctx]);
    return (
      <StyledRadioItem {...props} ref={forwardedRef}>
        {children}
        <DropdownMenuItemIndicator>
          <Icon icon="checkmark" size={space(16)} />
        </DropdownMenuItemIndicator>
        {rightContent && <RightSlot>{rightContent}</RightSlot>}
      </StyledRadioItem>
    );
  },
);
