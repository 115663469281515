import type { DisplayableError } from '@meterup/common';
import type { GraphQLError } from 'graphql';
import { ClientBadRequestError, ResourceNotFoundError, UnexpectedError } from '@meterup/common';
import { ClientError } from 'graphql-request';

/*
 * Known extension error codes that we support, will be defined from backend.
 */
export enum GraphQLExtensionErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  BadRequest = 'BAD_REQUEST',
}

export function getGraphQLError(originalError: Error): GraphQLError | undefined {
  let error = originalError;
  if (!(error instanceof ClientError) && error?.cause != null && error.cause instanceof Error) {
    error = error.cause;
  }
  if (!(error instanceof ClientError)) return undefined;

  return error.response.errors?.[0];
}

export function getGraphQLErrorMessageOrEmpty(error: Error): string {
  const gqlError = getGraphQLError(error);
  return gqlError?.message ? `: ${gqlError.message}` : '';
}

export function getExtensionErrorCode(
  error: GraphQLError | undefined,
): GraphQLExtensionErrorCode | unknown {
  return error?.extensions?.code;
}

export function clientErrorToDisplayableError(clientError: ClientError): DisplayableError {
  const graphqlError = getGraphQLError(clientError);

  switch (graphqlError?.extensions?.code) {
    case GraphQLExtensionErrorCode.NotFound:
    case GraphQLExtensionErrorCode.Unauthorized:
      return new ResourceNotFoundError('Sorry, we were unable to find this resource', clientError);
    case GraphQLExtensionErrorCode.BadRequest:
      return new ClientBadRequestError(graphqlError.message, clientError);
  }

  return new UnexpectedError(clientError.message, graphqlError);
}
