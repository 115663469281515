import type { CSS as StitchesCSS, PropertyValue, ScaleValue } from '@stitches/react';
import { createStitches } from '@stitches/react';

import { breakpoints } from './common/breakpoints';
import { palette } from './common/colors';
import { fontFamilies, makeFontString } from './common/fonts';
import { gradients } from './common/gradients';
import { shadows as commonShadows } from './common/shadows';

const SCALE_BASE_PX = 16;

export const DIALOG_OVERLAY_ZINDEX = 1000;
export const DIALOG_CONTENT_ZINDEX = DIALOG_OVERLAY_ZINDEX + 1;

const rem = (px: number): string => `${px / SCALE_BASE_PX}rem`;

const spacings = {
  0: rem(0),
  1: rem(1),
  2: rem(2),
  4: rem(4),
  6: rem(6),
  8: rem(8),
  10: rem(10),
  12: rem(12),
  14: rem(14),
  16: rem(16),
  20: rem(20),
  24: rem(24),
  28: rem(28),
  32: rem(32),
  36: rem(36),
  40: rem(40),
  44: rem(44),
  48: rem(48),
  52: rem(52),
  56: rem(56),
  60: rem(60),
  64: rem(64),
  68: rem(68),
  72: rem(72),
  76: rem(76),
  80: rem(80),
  84: rem(84),
  88: rem(88),
  92: rem(92),
  96: rem(96),
  100: rem(100),
  104: rem(104),
  108: rem(108),
  112: rem(112),
};

export const theme = createStitches({
  theme: {
    colors: { ...palette, ...gradients },
    space: spacings,
    sizes: spacings,
    fonts: {
      mono: makeFontString(fontFamilies.mono),
      sans: makeFontString(fontFamilies.sans),
      title: makeFontString(fontFamilies.title),
    },
    shadows: { ...commonShadows },
    fontWeights: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '500',
    },
    radii: {
      2: rem(2),
      4: rem(4),
      6: rem(6),
      8: rem(8),
      10: rem(10),
    },
    lineHeights: {
      12: rem(12),
      16: rem(16),
      18: rem(18),
      20: rem(20),
      24: rem(24),
      28: rem(28),
      32: rem(32),
    },
    fontSizes: {
      11: rem(11),
      12: rem(12),
      13: rem(13),
      14: rem(14),
      16: rem(16),
      20: rem(20),
      24: rem(24),
    },
  },
  utils: {
    _transformParts: () => ({
      $$translateX: 0,
      $$translateY: 0,
      $$skewX: 0,
      $$skewY: 0,
      $$scaleX: 1,
      $$scaleY: 1,
      $$rotate: 0,
      transform:
        'translateX($$translateX) translateY($$translateY) rotate($$rotate) skewX($$skewX) skewY($$skewY) scaleX($$scaleX) scaleY($$scaleY)',
    }),
    translateY: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateY: value,
    }),
    translateX: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateX: value,
    }),
    paddingX: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    truncate: () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    borderRadiusTop: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderTopRightRadius: value,
    }),
    borderRadiusBottom: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderBottomLeftRadius: value,
      borderBottomRightRadius: value,
    }),
    borderRadiusLeft: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderBottomLeftRadius: value,
    }),
    borderRadiusRight: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopRightRadius: value,
      borderBottomRightRadius: value,
    }),
    hStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: value,
    }),
    vStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: value,
    }),
    strokeAll: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0 0 0.5px ${value}, inset 0 0 0 0.5px ${value}`,
    }),
    strokeTopBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 ${value}, inset 0 -0.5px 0 ${value}, 0 0.5px 0 ${value}, inset 0 0.5px 0 ${value}`,
    }),
    strokeTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}`,
    }),
    strokeBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}`,
    }),
    strokeLeftRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeTopLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeTopRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeBottomLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeBottomRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
  },
  media: {
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
    maxSm: `(max-width: ${breakpoints.sm - 1}px)`,
    maxMd: `(max-width: ${breakpoints.md - 1}px)`,
    maxLg: `(max-width: ${breakpoints.lg - 1}px)`,
    maxXl: `(max-width: ${breakpoints.xl - 1}px)`,
    // Named presets
    mobile: `(max-width: ${breakpoints.sm}px)`,
    notMobile: `(min-width: ${breakpoints.sm + 1}px)`,
    tablet: `(min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.lg - 1}px)`,
    notDesktop: `(max-width: ${breakpoints.lg - 1}px)`,
    desktop: `(min-width: ${breakpoints.lg}px)`,
  },
});

export const {
  styled,
  css,
  keyframes,
  globalCss,
  theme: { fonts, shadows, colors, fontWeights },
} = theme;

export type CSS = StitchesCSS<typeof theme.config>;

export const darkThemeSelector = '.m-dark &';

export const activeThemeClassName = 'atto-active-theme';
export const activeThemeSelector = `.${activeThemeClassName} &`;

export const focusVisibleClassName = 'atto-focus-visible';
export const focusVisibleSelector = `.${focusVisibleClassName} &`;

export const safariOnlyCSS = (properties: CSS) => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      '&': properties,
    },
  },
});

export const firefoxOnlyCSS = (properties: CSS) => ({
  '@-moz-document url-prefix()': {
    '&': properties,
  },
});
