import type { ComponentProps } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const DeviceIcon = styled(Icon, {
  width: '$16',
  height: '$16',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

const DeviceName = styled(Body, {
  fontWeight: fontWeights.bold,
});

const DeviceHeading = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const DeviceMetadata = styled('div', { display: 'flex', gap: '$4' });

const DeviceDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  minWidth: '180px',
  minHeight: '100%',
  padding: '$12 $16',
  background: colors.bgNeutralLight,
  borderRadius: '12px 0 0 12px',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
  },
});

const DeviceSlots = styled('div', {
  display: 'flex',
  gap: '$12',
  width: 'fit-content',
  minWidth: 'fit-content',
  padding: '$12 $16',
  alignItems: 'end',
});

const DeviceContainer = styled('div', {
  display: 'flex',
  width: 'fit-content',
  minWidth: 'fit-content',
  background: colors.bgApplicationLight,
  borderRadius: '12px',
  boxShadow: shadows.deviceLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.deviceDark,
  },
});

type DeviceProps = {
  badges?: React.ReactNode;
  icon?: IconName;
  name?: React.ReactNode;
  slots?: React.ReactNode;
} & ComponentProps<typeof DeviceContainer>;

export function Device({ badges, icon, name, slots, ...remaining }: DeviceProps) {
  return (
    <DeviceContainer {...remaining}>
      {(name || badges) && (
        <DeviceDetails>
          {name && (
            <DeviceHeading>
              {icon && <DeviceIcon icon={icon} />}
              <DeviceName>{name}</DeviceName>
            </DeviceHeading>
          )}
          {badges && <DeviceMetadata>{badges}</DeviceMetadata>}
        </DeviceDetails>
      )}
      <DeviceSlots>{slots}</DeviceSlots>
    </DeviceContainer>
  );
}
