import React, { type ComponentProps } from 'react';

import { type IconName, Icon } from '../../assets/Icon/Icon';
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { selectors } from '../shared/styles';

export type MenuItemProps = {
  /**
   * Set whether the item is currently active (different from enabled, similar to active/selected in other menus and lists).
   */
  active?: boolean;
  /**
   * Displays a checkbox field at the start of the item, turns the whole row in a label.
   */
  checkbox?: boolean;
  children?: React.ReactNode;
  /**
   * A state where an item in the menu is enabled (hides icon, shows a checkmark).
   */
  enabled?: boolean;
  end?: React.ReactNode;
  icon?: IconName;
} & ComponentProps<typeof MenuItemContainer>;

const MenuItemStart = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$6',
  minWidth: '$20',

  '@notDesktop': {
    height: '$24',
    maxHeight: '$24',
    minHeight: '$24',
  },

  '@desktop': {
    height: '$20',
    maxHeight: '$20',
    minHeight: '$20',
  },
});

const MenuItemIcon = styled(Icon, {
  width: '$16',
  height: '$16',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

const MenuItemChildren = styled(Body, {
  width: '100%',
  color: colors.bodyNeutralLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const MenuItemEnd = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '@notDesktop': {
    height: '$24',
    maxHeight: '$24',
    minHeight: '$24',
  },

  '@desktop': {
    height: '$20',
    maxHeight: '$20',
    minHeight: '$20',
  },
});

const menuItemInner = {
  display: 'flex',
  gap: '$6',
  padding: '$4 $8',
  cursor: 'pointer',
};

const MenuItemLabel = styled('label', {
  ...menuItemInner,
});

const MenuItemTarget = styled('div', {
  ...menuItemInner,
});

const activeStyles = {
  background: colors.bgBrandLight,
  strokeAll: colors.strokeBrandLight,

  [`${MenuItemIcon}`]: {
    color: colors.bodyBrandLight,
  },

  [`${MenuItemChildren}`]: {
    color: colors.headingBrandLight,
  },

  [selectors.hover]: {
    background: colors.bgBrandLight,
    strokeAll: colors.strokeBrandLight,

    [`${MenuItemIcon}`]: {
      color: colors.bodyBrandLight,
    },

    [`${MenuItemChildren}`]: {
      color: colors.headingBrandLight,
    },
  },

  [darkThemeSelector]: {
    background: colors.bgBrandDark,
    strokeAll: colors.strokeBrandDark,

    [`${MenuItemIcon}`]: {
      color: colors.bodyBrandDark,
    },

    [`${MenuItemChildren}`]: {
      color: colors.headingBrandDark,
    },

    [selectors.hover]: {
      background: colors.bgBrandDark,
      strokeAll: colors.strokeBrandDark,

      [`${MenuItemIcon}`]: {
        color: colors.bodyBrandDark,
      },

      [`${MenuItemChildren}`]: {
        color: colors.headingBrandDark,
      },
    },
  },
};

const MenuItemContainer = styled('li', {
  position: 'relative',
  zIndex: 1,
  width: '100%',
  background: colors.bgApplicationLight,
  borderRadius: '$8',
  cursor: 'pointer',
  outline: 'none',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  [selectors.hover]: {
    zIndex: 2,
    background: colors.bgNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [`${MenuItemIcon}`]: {
      color: colors.bodyNeutralLight,
    },

    [`${MenuItemChildren}`]: {
      color: colors.headingNeutralLight,
    },

    [darkThemeSelector]: {
      background: colors.bgNeutralDark,
      strokeAll: colors.strokeNeutralDark,

      [`${MenuItemIcon}`]: {
        color: colors.bodyNeutralDark,
      },

      [`${MenuItemChildren}`]: {
        color: colors.headingNeutralDark,
      },
    },
  },

  [selectors.focus]: {
    zIndex: 4,
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    active: {
      true: {
        zIndex: 3,
        ...activeStyles,
      },
    },
    disabled: {
      true: {
        zIndex: 0,

        [`${MenuItemLabel}, ${MenuItemTarget}`]: {
          cursor: 'not-allowed',
        },

        [`${MenuItemIcon}, ${MenuItemChildren}`]: {
          color: colors.disabledNeutralLight,
        },

        [selectors.hover]: {
          background: colors.bgApplicationLight,
          strokeAll: colors.transparent,

          [darkThemeSelector]: {
            background: colors.bgApplicationDark,
          },

          [`${MenuItemIcon}, ${MenuItemChildren}`]: {
            color: colors.disabledNeutralLight,

            [darkThemeSelector]: {
              color: colors.disabledNeutralDark,
            },
          },
        },
      },
    },
    enabled: {
      true: {
        zIndex: 3,
        ...activeStyles,
      },
    },
  },
});

export const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>(
  ({ active, checkbox, children, end, enabled, icon, ...remaining }, ref) => {
    const renderInternals = (
      <>
        <MenuItemStart>
          {checkbox && <CheckboxInput />}
          {enabled && <MenuItemIcon icon="checkmark" />}
          {!enabled && icon && <MenuItemIcon icon={icon} />}
        </MenuItemStart>
        <MenuItemChildren>{children}</MenuItemChildren>
        {end && <MenuItemEnd>{end}</MenuItemEnd>}
      </>
    );

    return (
      <MenuItemContainer tabIndex={0} active={active} enabled={enabled} {...remaining} ref={ref}>
        {checkbox ? (
          <MenuItemLabel>{renderInternals}</MenuItemLabel>
        ) : (
          <MenuItemTarget>{renderInternals}</MenuItemTarget>
        )}
      </MenuItemContainer>
    );
  },
);
MenuItem.displayName = 'MenuItem';

export type MenuGroupProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
} & ComponentProps<typeof MenuGroupContainer>;

const MenuGroupLabel = styled(Small, {
  padding: '0 $12 $4 34px',
  fontWeight: fontWeights.bold,
});

const MenuGroupContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  '&:not(:first-child)': {
    position: 'relative',
    paddingTop: '$12',

    '&::before': {
      content: '',
      position: 'absolute',
      top: '5.5px',
      display: 'flex',
      width: '100%',
      height: '1px',
      background: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        background: colors.strokeNeutralDark,
      },
    },
  },
});

export function MenuGroup({ children, label, ...remaining }: MenuGroupProps) {
  return (
    <MenuGroupContainer {...remaining}>
      {label && <MenuGroupLabel>{label}</MenuGroupLabel>}
      <ul>{children}</ul>
    </MenuGroupContainer>
  );
}

export type MenuProps = {
  children?: React.ReactNode;
} & ComponentProps<typeof MenuContainer>;

const MenuContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '$2',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },
});

export function Menu({ children, ...remaining }: MenuProps) {
  return <MenuContainer {...remaining}>{children}</MenuContainer>;
}
