/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import { Button } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../../hooks/useFormStorage';
import { route } from '../../../routes';

function OnboardingHeader() {
  // Reset the flow
  const navigate = useNavigate();
  const [_onChange, _temporaryFormValues, setTemporaryFormValues] =
    useFormStorage(REQUEST_QUOTE_FORM_KEY);
  const onClickClear = useCallback(() => {
    setTemporaryFormValues({});
    navigate(route('welcome'));
  }, []);

  return (
    <header className="absolute top-0 right-0 p-6">
      <Button
        arrangement="leading-icon"
        icon="plus-circle"
        onClick={onClickClear}
        variant="secondary"
      >
        New location
      </Button>
    </header>
  );
}

export default OnboardingHeader;
