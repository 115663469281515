import type { AriaSelectProps } from '@react-types/select';
import { mergeRefs } from '@react-aria/utils';
import * as React from 'react';
import { HiddenSelect, useSelect } from 'react-aria';
import { Button as RACButton, Popover } from 'react-aria-components';
import { useSelectState } from 'react-stately';
import useMeasure from 'react-use-measure';

import type { FromSingleSelectionProps } from '../../common/props';
import type { ControlSize } from '../../controls/shared/types';
import { type IconName, Icon } from '../../assets/Icon/Icon';
import { useControlSize } from '../../common/control_size';
import { contentStyles } from '../../common/menus';
import { toSingleSelectionProps } from '../../common/props';
import {
  iconColor,
  labelColor,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
} from '../../controls/Button/button_styles';
import { selectors, transitions } from '../../controls/shared/styles';
import { darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { ComboBoxList } from '../ComboBox/ComboBoxList';

type SelectProps<T> = FromSingleSelectionProps<AriaSelectProps<T>> & {
  controlSize?: ControlSize;
  disabled?: boolean;
  icon?: IconName;
  invalid?: boolean;
  placeholder?: string;
  width?: string;
  maxWidth?: string | number;
  indeterminate?: boolean;
  minWidth?: string | number;
  showPlaceholderForIndeterminate?: boolean;
};

const SelectIcon = styled(Icon, {
  alignSelf: 'center',
  width: '$$iconSize',
  height: '$$iconSize',
  color: iconColor,
});

export const SelectValue = styled('span', Body, {
  width: '100%',
  fontWeight: fontWeights.bold,
  textAlign: 'left',
  truncate: true,
  transition: transitions.control,

  [darkThemeSelector]: {
    color: labelColor,
  },

  variants: {
    size: {
      small: {
        lineHeight: '$16',
        fontSize: '$12',
      },
      medium: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      large: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      'x-large': {
        lineHeight: '$24',
        fontSize: '$16',
      },
    },
  },
});

export const SelectTrigger = styled(RACButton, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$$buttonGap',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  opacity: 1,
  backgroundColor: '$white',
  borderRadius: '$8',
  truncate: true,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  [darkThemeSelector]: {
    background: '$gray800',
  },

  variants: {
    size: {
      small: {
        $$buttonGap: '$space$4',
        minWidth: '26px',
        minHeight: '$24',
        $$paddingX: '$space$6',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      medium: {
        $$buttonGap: '$space$4',
        minWidth: '$32',
        minHeight: '$28',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
      },
      large: {
        $$buttonGap: '$space$6',
        minWidth: '$40',
        minHeight: '$36',
        $$paddingX: '$space$12',
        $$paddingY: '$space$8',
        $$iconSize: '$space$12',
        borderRadius: '$10',
      },
      'x-large': {
        $$buttonGap: '$space$8',
        minHeight: '$44',
        $$paddingX: '$space$20',
        $$paddingY: '$space$10',
        $$iconSize: '$space$12',
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
        ...secondaryDisabledStyles,
      },
      false: {
        ...secondaryEnabledStyles,
      },
    },
    invalid: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      invalid: true,
      css: {
        boxShadow: shadows.fieldErrorLight,

        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },

        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,

          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },
  ],
});
SelectTrigger.displayName = 'SelectTrigger';

const SelectPopover = styled(Popover, contentStyles, {
  padding: '$4',
  overflow: 'auto',
});
SelectPopover.displayName = 'SelectPopover';

export const SelectContainer = styled('div', {
  minWidth: 0,
});
SelectContainer.displayName = 'SelectContainer';

function SelectInner<T extends object>(props: SelectProps<T>, ref: React.Ref<HTMLButtonElement>) {
  const renamedProps = toSingleSelectionProps(props);
  const {
    placeholder = 'Select...',
    indeterminate,
    showPlaceholderForIndeterminate,
  } = renamedProps;

  const controlSize = useControlSize(props.controlSize, 'medium');

  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const state = useSelectState(renamedProps);

  const [measureRef, { width: inputWidth }] = useMeasure();

  const { triggerProps, valueProps, menuProps } = useSelect(renamedProps, state, triggerRef);

  return (
    <SelectContainer
      css={{ width: props.width, maxWidth: props.maxWidth, minWidth: props.minWidth }}
    >
      <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} name={props.name} />
      <SelectTrigger
        {...triggerProps}
        ref={mergeRefs(ref, triggerRef, measureRef as unknown as React.Ref<HTMLButtonElement>)}
        size={controlSize}
        invalid={props.invalid}
        css={{ width: props.width, maxWidth: props.maxWidth, minWidth: props.minWidth }}
        isDisabled={props.disabled ?? false}
      >
        {props.icon && <SelectIcon icon={props.icon} />}
        <SelectValue {...valueProps} size={controlSize}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {indeterminate ? (
            showPlaceholderForIndeterminate ? (
              placeholder
            ) : (
              <>&ndash;&nbsp;</>
            )
          ) : state.selectedItem ? (
            state.selectedItem.rendered
          ) : (
            placeholder
          )}
        </SelectValue>
        <SelectIcon icon="chevrons-vertical" />
      </SelectTrigger>
      <SelectPopover
        triggerRef={triggerRef}
        isOpen={state.isOpen}
        onOpenChange={state.setOpen}
        placement="bottom start"
        style={{
          minWidth: inputWidth,
          // 400px is an arbitrary width that prevents unusually wide menus.
          // Generally we want the menu to be only as wide as necessary to fit the content,
          // but beyond 400px text should wrap to a new line.
          maxWidth: Math.max(inputWidth, 400),
        }}
      >
        <ComboBoxList {...menuProps} state={state} />
      </SelectPopover>
    </SelectContainer>
  );
}

export const Select = React.forwardRef(SelectInner) as <T extends object>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> },
) => ReturnType<typeof SelectInner>;

export { Item as SelectItem, Section as SelectSection } from 'react-stately';
export type { SelectProps };
