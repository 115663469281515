import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { isDefined } from '../../utilities/isDefined';

const ToastIcon = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',
});

const ToastPosition = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '$20',
});

const ToastHeading = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
  fontWeight: fontWeights.bold,
  color: '$$headingColor',
});

const ToastStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  gap: '$6',
  width: 'fit-content',
});

const ToastEnd = styled(ControlGroup, {
  alignSelf: 'flex-start',
});

const ToastContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$12',
  width: 'max-content',
  maxWidth: '700px',
  padding: '$8 $16',
  backgroundColor: '$$backgroundColor',
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },

  '&:before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '$10',
    strokeAll: '$$strokeColor',
  },

  variants: {
    variant: {
      alternative: {
        $$backgroundColor: colors.bgAlternativeLight,
        $$headingColor: colors.headingAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,
        $$strokeColor: colors.strokeAlternativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAlternativeDark,
          $$headingColor: colors.headingAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
          $$strokeColor: colors.strokeAlternativeDark,
        },
      },
      attention: {
        $$backgroundColor: colors.bgAttentionLight,
        $$headingColor: colors.headingAttentionLight,
        $$iconColor: colors.iconAttentionLight,
        $$strokeColor: colors.strokeAttentionLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAttentionDark,
          $$headingColor: colors.headingAttentionDark,
          $$iconColor: colors.iconAttentionDark,
          $$strokeColor: colors.strokeAttentionDark,
        },
      },
      brand: {
        $$backgroundColor: colors.bgBrandLight,
        $$headingColor: colors.headingBrandLight,
        $$iconColor: colors.iconBrandLight,
        $$strokeColor: colors.strokeBrandLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgBrandDark,
          $$headingColor: colors.headingBrandDark,
          $$iconColor: colors.iconBrandDark,
          $$strokeColor: colors.strokeBrandDark,
        },
      },
      negative: {
        $$backgroundColor: colors.bgNegativeLight,
        $$headingColor: colors.headingNegativeLight,
        $$iconColor: colors.iconNegativeLight,
        $$strokeColor: colors.strokeNegativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNegativeDark,
          $$headingColor: colors.headingNegativeDark,
          $$iconColor: colors.iconNegativeDark,
          $$strokeColor: colors.strokeNegativeDark,
        },
      },
      neutral: {
        $$backgroundColor: colors.bgNeutralLight,
        $$headingColor: colors.headingNeutralLight,
        $$iconColor: colors.iconNeutralLight,
        $$strokeColor: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNeutralDark,
          $$headingColor: colors.headingNeutralDark,
          $$iconColor: colors.iconNeutralDark,
          $$strokeColor: colors.strokeNeutralDark,
        },
      },
      positive: {
        $$backgroundColor: colors.bgPositiveLight,
        $$headingColor: colors.headingPositiveLight,
        $$iconColor: colors.iconPositiveLight,
        $$strokeColor: colors.strokePositiveLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgPositiveDark,
          $$headingColor: colors.headingPositiveDark,
          $$iconColor: colors.iconPositiveDark,
          $$strokeColor: colors.strokePositiveDark,
        },
      },
    },
  },
});

export type ToastVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

export interface ToastProps {
  actions?: React.ReactNode;
  heading: ReactNode;
  icon?: IconName;
  variant?: ToastVariant;
}

export const Toast = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      actions,
      heading,
      icon,
      variant = 'neutral',
      ...props
    }: PolymorphicComponentProps<Tag, ToastProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <ToastContainer {...props} ref={forwardedRef} variant={variant}>
      <ToastStart>
        {isDefined(icon) && (
          <ToastPosition>
            <ToastIcon icon={icon} />
          </ToastPosition>
        )}
        <ToastHeading>{heading}</ToastHeading>
      </ToastStart>
      {actions && (
        <ToastEnd relation="separate" size="small">
          {actions}
        </ToastEnd>
      )}
    </ToastContainer>
  ),
) as Polymorphic.ForwardRefComponent<'div', ToastProps>;
