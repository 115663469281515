import type { GraphQLError } from 'graphql';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React from 'react';

import { getGraphQLError } from '../utils';

interface ErrorBoundaryState {
  error?: Error;
  graphQLError?: GraphQLError;
}

export interface GraphQLErrorBoundaryProps {
  fallback: React.FC<
    ErrorBoundaryState & {
      error: NonNullable<ErrorBoundaryState['error']>;
    }
  >;
  children: React.ReactNode;
}

/**
 * Note that this doesn't prevent React from logging the error to the console.
 * https://github.com/facebook/react/issues/15069
 */
class ErrorBoundary extends React.Component<GraphQLErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: GraphQLErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, graphQLError: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { graphQlError: getGraphQLError(error), error };
  }

  render() {
    const { error, graphQLError } = this.state;

    if (error) {
      return this.props.fallback({
        error,
        graphQLError,
      });
    }

    return this.props.children;
  }
}

export function GraphQLErrorBoundary(props: GraphQLErrorBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundary {...props} />
    </QueryErrorResetBoundary>
  );
}
