import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { Text } from '../../text/Text';
import { isDefined } from '../../utilities/isDefined';

export interface TabProps {
  /**
   * Marks the currently active tab.
   * */
  active?: boolean;
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Written label for the tab.
   * */
  children: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
}

const TabContainer = styled('button', Text, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '$$focusRing',
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
    $$focusRing: shadows.focusRingLight,

    [darkThemeSelector]: {
      $$focusRing: shadows.focusRingDark,
    },
  },

  '@notDesktop': {
    gap: '$6',
    padding: '$6 $8',
    borderRadius: '10px',
    fontSize: '$14',
    lineHeight: '$20',
  },

  '@desktop': {
    gap: '$4',
    padding: '$4 $6',
    borderRadius: '$8',
    fontSize: '$12',
    lineHeight: '$16',
  },

  variants: {
    hasAnnotation: {
      true: {
        '@notDesktop': {
          paddingRight: '$6',
        },
        '@desktop': {
          paddingRight: '$4',
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isActive: {
      true: {
        backgroundColor: colors.bgBrandLight,
        $$annotationBackground: colors.tokenBgBrandLight,
        $$annotationColor: colors.headingBrandLight,
        $$iconColor: colors.bodyBrandLight,
        color: colors.headingBrandLight,
        strokeAll: colors.strokeBrandLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          $$annotationBackground: colors.tokenBgBrandDark,
          $$annotationColor: colors.headingBrandDark,
          color: colors.headingBrandDark,
          $$iconColor: colors.bodyBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
      false: {
        $$annotationBackground: colors.bgNeutralLight,
        $$annotationColor: colors.bodyNeutralLight,
        $$iconColor: colors.bodyNeutralLight,
        color: colors.headingNeutralLight,
        [darkThemeSelector]: {
          $$annotationBackground: colors.bgNeutralDark,
          $$annotationColor: colors.bodyNeutralDark,
          $$iconColor: colors.bodyNeutralDark,
          color: colors.headingNeutralDark,
        },
        [selectors.hover]: {
          backgroundColor: colors.bgNeutralLight,
          $$annotationBackground: colors.tokenBgNeutralLight,
          $$annotationColor: colors.bodyNeutralLight,
          $$iconColor: colors.bodyNeutralLight,
          color: colors.headingNeutralLight,
          strokeAll: colors.strokeNeutralLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgNeutralDark,
            $$annotationBackground: colors.gray700,
            $$annotationColor: colors.bodyNeutralDark,
            $$iconColor: colors.bodyNeutralDark,
            color: colors.headingNeutralDark,
            strokeAll: colors.strokeNeutralDark,
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        $$annotationBackground: colors.internalBgLight,
        $$annotationColor: colors.internalBodyLight,
        color: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,
        [selectors.hover]: {
          $$annotationBackground: colors.internalBgLight,
          $$annotationColor: colors.internalBodyLight,
          color: colors.internalHeadingLight,
          $$iconColor: colors.internalBodyLight,
        },
        [darkThemeSelector]: {
          $$annotationBackground: colors.internalBgDark,
          $$annotationColor: colors.internalBodyDark,
          color: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
          [selectors.hover]: {
            $$annotationBackground: colors.internalBgDark,
            $$annotationColor: colors.internalBodyDark,
            color: colors.internalHeadingDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
    {
      internal: true,
      isActive: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.internalBgLight,
          strokeAll: colors.internalStrokeLight,
          [darkThemeSelector]: {
            backgroundColor: colors.internalBgDark,
            strokeAll: colors.internalStrokeDark,
          },
        },
      },
    },
  ],
});
TabContainer.displayName = 'TabContainer';

const TabIcon = styled(Icon, {
  color: '$$iconColor',
  width: '$16',
  height: '$16',
});
TabIcon.displayName = 'TabIcon';

const TabAnnotation = styled('div', Small, {
  alignItems: 'center',
  justifyContent: 'center',
  transition: transitions.control,
  display: 'flex',
  marginLeft: '$2',
  background: '$$annotationBackground',
  color: '$$annotationColor',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,

  '@notDesktop': {
    minWidth: '22px',
    height: '$20',
    minHeight: '$20',
    paddingX: '$6',
    borderRadius: '$8',
  },

  '@desktop': {
    minWidth: '18px',
    height: '$16',
    minHeight: '$16',
    paddingX: '$4',
    borderRadius: '$6',
  },
});
TabAnnotation.displayName = 'TabAnnotation';

function TabInner<Tag extends React.ElementType>(
  {
    active = false,
    annotation,
    children,
    icon,
    internal,
    ...remaining
  }: PolymorphicComponentProps<Tag, TabProps>,
  forwardedRef: PolymorphicRef<Tag>,
) {
  return (
    <TabContainer
      type="button"
      role="tab"
      aria-selected={active ? 'true' : 'false'}
      {...remaining}
      ref={forwardedRef}
      isActive={active}
      hasAnnotation={isDefined(annotation)}
      internal={internal}
    >
      {isDefined(icon) && <TabIcon icon={icon} />}
      {children}
      {isDefined(annotation) && <TabAnnotation>{annotation}</TabAnnotation>}
    </TabContainer>
  );
}

export const Tab = React.forwardRef(TabInner) as <D extends React.ElementType = 'button'>(
  props: PolymorphicComponentProps<D, TabProps> & { ref?: PolymorphicRef<D> },
) => ReturnType<typeof TabInner>;

const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
  height: '100%',

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '44px',
  },
});
TabsContainer.displayName = 'TabsContainer';

export type TabsProps = {
  /**
   * Pass `Tab` components into this for automatically laid out tabs.
   * */
  children: React.ReactNode;
};

export function Tabs({ children, ...remaining }: TabsProps) {
  return (
    <TabsContainer role="tablist" {...remaining}>
      {children}
    </TabsContainer>
  );
}
