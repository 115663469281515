import type { IconName } from '../Icon/Icon';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { space } from '../../utilities/shared/sizes';
import { Icon } from '../Icon/Icon';

const FramedIconIcon = styled(Icon, {
  display: 'block',
  width: '46%',
  height: '46%',
});

const FramedIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgApplicationLight,
  color: colors.bodyNeutralLight,
  boxShadow: shadows.manufacturerLight,
  borderRadius: '16px',
  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },
  variants: {
    radius: {
      large: {
        borderRadius: '16px',
      },
      medium: {
        borderRadius: '10px',
      },
      small: {
        borderRadius: '6px',
      },
    },
  },
});

export interface FramedIconProps {
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set a size of the component.
   */
  size?: number;
}

const getRadius = (size: number) => {
  switch (true) {
    case size >= 40:
      return 'large';
    case size >= 28:
      return 'medium';
    default:
      return 'small';
  }
};

export function FramedIcon({ icon, size = space(28), ...remaining }: FramedIconProps) {
  return (
    <FramedIconContainer
      radius={getRadius(size)}
      style={{ width: `${size}px`, height: `${size}px` }}
      {...remaining}
    >
      <FramedIconIcon icon={icon} />
    </FramedIconContainer>
  );
}
