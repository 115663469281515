import type { Node as CollectionNode } from '@react-types/shared';
import type { AriaPopoverProps, AriaTagGroupProps, AriaTagProps } from 'react-aria';
import type { ListState } from 'react-stately';
import * as React from 'react';
import {
  OverlayContainer,
  useFocus,
  useFocusRing,
  useOverlayPosition,
  useOverlayTrigger,
  useTag,
  useTagGroup,
} from 'react-aria';
import { mergeRefs } from 'react-merge-refs';
import { useListState, useOverlayTriggerState } from 'react-stately';
import useMeasure from 'react-use-measure';

import type { IconName } from '../../assets/Icon/Icon';
import type { FromMultipleSelectionProps } from '../../common/props';
import { Icon } from '../../assets/Icon/Icon';
import { toMultipleSelectionProps } from '../../common/props';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { space } from '../../utilities/shared/sizes';
import { Badge } from '../Badge/Badge';
import { BaseInputContainer } from '../BaseInput/BaseInput';
import { MultiComboBoxPopover } from './MultiComboBoxPopover';
import { MultiComboBoxOverlay } from './MultiComboxBoxOverlay';

const TagsList = styled('div', {
  cursor: 'text',
  width: '100%',
  '&:focus-visible': {
    outline: 'none',
  },
  variants: {
    size: {
      large: {
        paddingY: '$8',
        paddingX: '$12',
      },
      medium: {
        paddingY: '$4',
        paddingX: '$8',
      },
      small: {
        paddingY: '$4',
        paddingX: '$6',
      },
    },
  },
});

const TagsListInner = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '$4',
  minHeight: '$20', // this is the height of the "small" badge size
  outline: 'none',
});

const BadgeContainer = styled('div', {
  height: '$20',
  cursor: 'pointer',
  '&[data-focus-visible=true]': {
    borderRadius: '$8',
    boxShadow: '0 0 0 2px $colors$blue500',
    outline: 'none',
  },
});

const MultiComboBoxBadge = styled(Badge, {
  verticalAlign: 'top',
});

const Placeholder = styled(Body, {
  display: 'block',
  color: '$$placeholderColor',
});

const MultiComboBoxAdd = styled(Icon, {
  color: colors.iconNeutralLight,
  cursor: 'pointer',

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  [selectors.hover]: {
    color: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      color: colors.bodyNeutralDark,
    },
  },
});

const MultiComboBoxIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: colors.iconNeutralLight,
  cursor: 'pointer',

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  [selectors.hover]: {
    color: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      color: colors.bodyNeutralDark,
    },
  },
});

const MultiComboBoxContainer = styled(BaseInputContainer, {
  alignItems: 'center',

  variants: {
    icon: {
      true: {
        paddingLeft: '$8',
      },
    },
  },
});

type MultiComboBoxSize = 'small' | 'medium' | 'large';

interface TagProps<T> extends AriaTagProps<T> {
  state: ListState<T>;
  isDisabled?: boolean;
}

function Tag<T>(props: TagProps<T>) {
  const { item, state } = props;
  const ref = React.useRef(null);
  const { focusProps, isFocusVisible } = useFocusRing({ within: true });
  const { rowProps, gridCellProps } = useTag(props, state, ref);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (props.isDisabled) return;

    if (e.key === 'Backspace') {
      state.selectionManager.toggleSelection(item.key);
      e.stopPropagation();
      return;
    }

    rowProps.onKeyDown?.(e);
  };

  return (
    <BadgeContainer
      {...(props.isDisabled ? {} : rowProps)}
      {...(props.isDisabled ? {} : focusProps)}
      ref={ref}
      onKeyDown={onKeyDown}
      data-focus-visible={isFocusVisible}
    >
      <MultiComboBoxBadge {...gridCellProps} icon="cross" variant="brand" size="small">
        {item.rendered}
      </MultiComboBoxBadge>
    </BadgeContainer>
  );
}

function useCloseOnFocusOutside(
  overlayState: ReturnType<typeof useOverlayTriggerState>,
  triggerRef: React.RefObject<HTMLButtonElement>,
  overlayRef: React.RefObject<HTMLDivElement>,
) {
  React.useEffect(() => {
    const handleFocus = (e: FocusEvent) => {
      if (!overlayState.isOpen) return;

      if (
        !triggerRef.current?.contains(e.target as Node) &&
        !overlayRef.current?.contains(e.target as Node)
      ) {
        overlayState.close();
      }
    };

    document.addEventListener('focusin', handleFocus);

    return () => {
      document.removeEventListener('focusin', handleFocus);
    };
  }, [overlayRef, overlayState, triggerRef]);
}

interface MultiComboBoxProps<T> extends FromMultipleSelectionProps<AriaTagGroupProps<T>> {
  /**
   * The list of options to render inside the popover.
   * - Use `<MultiComboBoxItem>` to render an option.
   * - Use `<MultiComboBoxSection>` to group options into sections.
   */
  children: AriaTagGroupProps<T>['children'];
  /**
   * Whether the input is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * The icon to show in the input.
   */
  icon?: IconName;
  /**
   * The placeholder text to show when there are no selected items.
   * @default 'Select items'
   */
  placeholder?: string;
  /**
   * The placement of the popover relative to the input.
   * @default 'bottom start'
   */
  placement?: AriaPopoverProps['placement'];
  /**
   * Whether to show the "add" button in the input.
   * @default false
   */
  showAdd?: boolean;
  /**
   * The size of the input.
   * @default 'medium'
   */
  size?: MultiComboBoxSize;
  /**
   * The width of the popover.
   */
  width?: string | number;
  /**
   * Whether the input is in an indeterminate state.
   */
  indeterminate?: boolean;
  renderSelected?: (selectedItems: CollectionNode<T>[]) => React.ReactElement;
  enableSelectAll?: boolean;
}

function MultiComboBoxInner<T extends object>(
  props: MultiComboBoxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const renamedProps = toMultipleSelectionProps(props);

  const {
    icon,
    placement = 'bottom start',
    showAdd,
    size = 'medium',
    placeholder = 'Select items',
    indeterminate,
    enableSelectAll = false,
  } = renamedProps;

  const [measureRef, { width: inputWidth }] = useMeasure();
  const [isFocused, setIsFocused] = React.useState(false);
  const { focusProps } = useFocus({ onFocusChange: (value) => setIsFocused(value) });

  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);

  const tagGroupState = useListState({
    ...renamedProps,
    selectionMode: 'multiple',
    selectionBehavior: 'toggle',
  });

  const overlayState = useOverlayTriggerState({});

  const { overlayProps } = useOverlayTrigger(
    {
      type: 'dialog',
    },
    overlayState,
  );

  const { overlayProps: positionProps, updatePosition } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement,
    offset: 4,
    isOpen: overlayState.isOpen,
  });

  const { gridProps } = useTagGroup(renamedProps, tagGroupState, triggerRef);

  const selectedItems = [...tagGroupState.collection.getKeys()]
    .filter((key) => tagGroupState.selectionManager.isSelected(key))
    .map((key) => tagGroupState.collection.getItem(key))
    .filter((item) => !!item) as CollectionNode<T>[];

  React.useEffect(() => {
    updatePosition();
  }, [selectedItems.length, updatePosition]);

  const openPopoverAndFocusInput = () => {
    if (!overlayState.isOpen) {
      overlayState.open();
    }
  };

  // when an element outside the popover is focused, close the popover
  useCloseOnFocusOutside(overlayState, triggerRef, overlayRef);

  return (
    <div ref={ref}>
      <MultiComboBoxContainer
        {...focusProps}
        icon={!!icon}
        size={size}
        onClick={openPopoverAndFocusInput}
        ref={mergeRefs([triggerRef, measureRef])}
        isDisabled={renamedProps.isDisabled}
        data-is-focused={isFocused || overlayState.isOpen ? 'true' : undefined}
        tabIndex={renamedProps.isDisabled ? undefined : 0}
        onKeyDown={(e) => {
          if (renamedProps.isDisabled) return;

          if (e.key === 'Backspace') {
            tagGroupState.selectionManager.clearSelection();
          }

          if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
            openPopoverAndFocusInput();
          }
        }}
      >
        {icon && <MultiComboBoxIcon icon={icon} size={size === 'small' ? space(12) : space(16)} />}
        <TagsList size={size}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {props?.renderSelected ? (
            props.renderSelected(selectedItems)
          ) : // eslint-disable-next-line no-nested-ternary
          indeterminate ? (
            <>&nbsp;&ndash;</>
          ) : selectedItems.length ? (
            <TagsListInner
              {...gridProps}
              onKeyDownCapture={(e) => {
                if (renamedProps.isDisabled) return;

                // override useListState behavior of clearing the value when pressing Escape
                if (e.key === 'Escape') {
                  e.stopPropagation();
                  return;
                }

                gridProps.onKeyDownCapture?.(e);
              }}
            >
              {selectedItems.map((item) => (
                <Tag
                  key={item.key}
                  item={item}
                  state={tagGroupState}
                  isDisabled={renamedProps.isDisabled}
                />
              ))}
              {showAdd && (
                <MultiComboBoxAdd icon="plus" size={size === 'small' ? space(10) : space(14)} />
              )}
            </TagsListInner>
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
        </TagsList>
      </MultiComboBoxContainer>
      {overlayState.isOpen && (
        <OverlayContainer>
          <MultiComboBoxPopover
            {...overlayProps}
            {...positionProps}
            popoverRef={overlayRef}
            onClose={overlayState.close}
            state={overlayState}
            placement={placement}
            style={{
              ...positionProps.style,
              width: renamedProps.width,
              minWidth: inputWidth,
              maxWidth: renamedProps.width === '100%' ? inputWidth : '400px',
            }}
          >
            <MultiComboBoxOverlay
              {...renamedProps}
              onSelectionChange={(e) => {
                if (e === 'all' && enableSelectAll) {
                  tagGroupState.selectionManager.toggleSelectAll();
                } else {
                  tagGroupState.selectionManager.setSelectedKeys(e);
                }
              }}
              isOpen={overlayState.isOpen}
              onClose={overlayState.close}
              selectedKeys={tagGroupState.selectionManager.selectedKeys}
              disabledKeys={renamedProps.disabledKeys}
              enableSelectAll={enableSelectAll}
            />
          </MultiComboBoxPopover>
        </OverlayContainer>
      )}
    </div>
  );
}

export const MultiComboBox = React.forwardRef(MultiComboBoxInner) as <T extends object>(
  props: MultiComboBoxProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof MultiComboBoxInner>;

export { Item as MultiComboBoxItem, Section as MultiComboBoxSection } from 'react-stately';
export type { MultiComboBoxProps };
