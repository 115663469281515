import { styled } from '@stitches/react';

import { fontFamilies } from '../../common/fonts';
import { colors, darkThemeSelector, fontWeights } from '../../stitches.config';
import { Text } from '../../text/Text';

export const ShortcutKey = styled(Text, {
  display: 'inline-flex',
  width: 'fit-content',
  padding: '$2 $4',
  backgroundColor: colors.gray50,
  borderRadius: '$4',
  color: colors.gray500,
  font: `${fontFamilies.sans}`,
  fontSize: '$11',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,
  lineHeight: '$12',
  strokeAll: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    backgroundColor: colors.gray800,
    color: colors.gray100,
    strokeAll: colors.strokeNeutralDark,
  },
});

export const ShortcutModifier = styled(Text, {
  display: 'inline-flex',
  width: 'fit-content',
  color: colors.gray400,
  font: `${fontFamilies.sans}`,
  fontSize: '$11',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,
  lineHeight: '$12',
  [darkThemeSelector]: {
    color: colors.gray200,
  },
});

export const Shortcut = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$2',
  padding: '0 $1',
  variants: {
    control: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      neutral: {},
      primary: {},
    },
  },
  compoundVariants: [
    // Destructive
    {
      variant: 'destructive',
      control: false,
      css: {
        [`${ShortcutKey}`]: {
          backgroundColor: colors.red50,
          color: colors.red600,
          strokeAll: colors.strokeNegativeLight,
          [darkThemeSelector]: {
            backgroundColor: colors.red900,
            color: colors.red100,
            strokeAll: colors.strokeNegativeDark,
          },
        },
        [`${ShortcutModifier}`]: {
          color: colors.red400,
          [darkThemeSelector]: {
            color: colors.red200,
          },
        },
      },
    },
    // Destructive, control
    {
      variant: 'destructive',
      control: true,
      css: {
        [`${ShortcutKey}`]: {
          backgroundColor: colors.red700,
          color: colors.red50,
          strokeAll: colors.controlStrokeDestructiveDark,
        },
        [`${ShortcutModifier}`]: {
          color: colors.red100,
        },
      },
    },
    // Neutral
    {
      variant: 'neutral',
      control: false,
      css: {},
    },
    {
      variant: 'neutral',
      control: true,
      css: {},
    },
    // Primary
    {
      variant: 'primary',
      control: false,
      css: {
        [`${ShortcutKey}`]: {
          backgroundColor: colors.brand50,
          color: colors.brand600,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.brand900,
            color: colors.brand100,
            strokeAll: colors.strokeBrandDark,
          },
        },
        [`${ShortcutModifier}`]: {
          color: colors.brand400,
          [darkThemeSelector]: {
            color: colors.brand200,
          },
        },
      },
    },
    // Primary, control
    {
      variant: 'primary',
      control: true,
      css: {
        [`${ShortcutKey}`]: {
          backgroundColor: colors.brand700,
          color: colors.brand50,
          strokeAll: colors.strokeBrandDark,
        },
        [`${ShortcutModifier}`]: {
          color: colors.brand100,
        },
      },
    },
  ],
});

Shortcut.defaultProps = {
  control: false,
  variant: 'neutral',
};
