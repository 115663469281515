import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const LargeBase = {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

const LargeSansSizes = {
  '@notDesktop': {
    fontSize: '18px',
    lineHeight: '$28',
  },
  '@desktop': {
    fontSize: '$16',
    lineHeight: '$24',
  },
};

const LargeMonoSizes = {
  '@notDesktop': {
    fontSize: '17px',
    lineHeight: '$28',
  },
  '@desktop': {
    fontSize: '15px',
    lineHeight: '$24',
  },
};

export const Large = styled(Text, LargeBase, {
  variants: {
    family: {
      regular: LargeSansSizes,
      monospace: LargeMonoSizes,
    },
  },
  defaultVariants: {
    family: 'regular',
  },
});
