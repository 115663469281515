import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { sizing } from '../../common/sizing';
import { selectors } from '../../controls/shared/styles';
import {
  colors,
  css,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { space } from '../../utilities/shared/sizes';
import { useCanScrollX } from './useCanScrollX';

const CellContents = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },
});

const CellBase = css({
  position: 'relative',
  display: 'table-cell',
  padding: '$6 $12',
  height: '$36',
  strokeBottom: colors.strokeNeutralLight,
  color: '$$rowTextColor',
  verticalAlign: 'middle',
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    strokeBottom: colors.strokeNeutralDark,
  },

  variants: {
    alignment: {
      start: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      end: {
        textAlign: 'right',
      },
    },
    condense: {
      true: {
        '@maxSm': {
          display: 'none',
        },
      },
      false: {},
    },
    internal: {
      true: {
        color: '$$internalBodyLight',

        [darkThemeSelector]: {
          color: '$$internalBodyDark',
        },
      },
      false: {},
    },
    isNavigable: {
      true: {},
      false: {},
    },
  },
});

const CellInnerBase = styled('div', CellBase, {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$14',
  lineHeight: '$16',
  fontVariant: 'tabular-nums',

  variants: {
    condense: {
      true: {
        '@maxSm': {
          display: 'none',
        },
      },
      false: {},
    },
    isLeading: {
      true: {
        color: '$$leadingColumnTextColor',
        fontWeight: fontWeights.bold,
      },
      false: {},
    },
  },
});

type CellProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    alignment?: 'start' | 'center' | 'end';
    condense?: boolean;
    isLeading?: boolean;
    isNavigable?: boolean;
    internal?: boolean;
  }
>;

function CellInner(
  { alignment, children, condense, isLeading, isNavigable, ...props }: CellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <CellInnerBase
      {...props}
      condense={condense}
      isLeading={isLeading}
      isNavigable={isNavigable}
      ref={ref}
    >
      <CellContents alignment={alignment}>{children}</CellContents>
    </CellInnerBase>
  );
}

const Cell = React.forwardRef<HTMLDivElement, CellProps>(CellInner);

const stateCellWidth = 8;
const bufferCellWidth = sizing.primary - stateCellWidth;

const BufferCell = styled('div', {
  display: 'table-cell',
  width: `${bufferCellWidth}px`,
  minWidth: `${bufferCellWidth}px`,
  maxWidth: `${bufferCellWidth}px`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
    isNested: {
      true: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
      false: {},
    },
  },
});

const StateCell = styled('div', {
  content: '',
  display: 'table-cell',
  width: `${stateCellWidth}px`,
  minWidth: `${stateCellWidth}px`,
  maxWidth: `${stateCellWidth}px`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
    isNested: {
      true: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
      false: {},
    },
  },
});

const LeadingStateCell = styled(StateCell, {
  borderRadius: '$10 0 0 $10',
});

const TrailingStateCell = styled(StateCell, {
  borderRadius: '0 $10 $10 0',
});

const TableContainer = styled('div', {
  width: '100%',
  paddingTop: '0.5px',
});

const TableBase = styled('div', {
  position: 'relative',
  display: 'table',
  width: '100%',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  variants: {
    isNested: {
      true: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
      false: {},
    },
  },
});

interface TableProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  isNested?: boolean;
}

const Main = React.forwardRef<HTMLDivElement, TableProps>(
  ({ children, isNested, ...props }, ref) => {
    const [scrollRef, canScrollX] = useCanScrollX();
    return (
      <TableContainer {...props} ref={mergeRefs([ref, scrollRef])} data-can-scroll-x={canScrollX}>
        <TableBase isNested={isNested}>{children}</TableBase>
      </TableContainer>
    );
  },
);

const Head = styled('div', {
  display: 'table-header-group',
});

const Body = styled('div', {
  display: 'table-row-group',
});

const RowBase = styled('div', {
  $$rowIconColor: colors.gray400,
  $$rowTextColor: colors.gray800,
  $$leadingColumnTextColor: colors.gray900,
  $$iconColor: colors.gray700,
  $$internalBodyLight: colors.internalBodyLight,
  $$internalBodyDark: colors.internalBodyDark,

  position: 'relative',
  zIndex: 1,
  width: '100%',
  display: 'table-row',

  [darkThemeSelector]: {
    $$rowIconColor: colors.gray300,
    $$rowTextColor: colors.gray50,
    $$leadingColumnTextColor: colors.white,
    $$rowShadow: colors.strokeNeutralDark,
    $$iconColor: colors.gray200,

    background: colors.bgApplicationDark,
  },

  variants: {
    isNavigable: {
      true: {
        cursor: 'pointer',

        [selectors.focus]: {
          zIndex: 3,
          padding: '0 50px',
          outline: 'none',

          boxShadow: shadows.focusRingLight,

          [darkThemeSelector]: {
            boxShadow: shadows.focusRingDark,
          },
        },
      },
      false: {},
    },
    isSelected: {
      true: {
        zIndex: 2,
        $$rowTextColor: colors.brand700,
        $$iconColor: colors.brand600,
        $$leadingColumnTextColor: colors.brand800,
        $$rowIconColor: colors.brand600,

        [darkThemeSelector]: {
          $$rowTextColor: colors.brand50,
          $$iconColor: colors.brand200,
          $$leadingColumnTextColor: colors.brand50,
          $$rowIconColor: colors.brand500,
        },

        [`& ${CellInnerBase}`]: {
          background: colors.bgBrandLight,
          strokeTopBottom: colors.strokeBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeTopBottom: colors.strokeBrandDark,
          },
        },

        [`& ${StateCell}`]: {
          background: colors.bgBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
          },
        },

        [`& ${LeadingStateCell}`]: {
          strokeNoRight: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoRight: colors.strokeBrandDark,
          },
        },

        [`& ${TrailingStateCell}`]: {
          strokeNoLeft: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoLeft: colors.strokeBrandDark,
          },
        },

        [selectors.hover]: {
          [`& ${CellInnerBase}`]: {
            background: colors.bgBrandLight,
            strokeTopBottom: colors.strokeBrandLight,

            [darkThemeSelector]: {
              background: colors.bgBrandDark,
              strokeTopBottom: colors.strokeBrandDark,
            },
          },

          [`& ${StateCell}`]: {
            background: colors.bgBrandLight,

            [darkThemeSelector]: {
              background: colors.bgBrandDark,
            },
          },

          [`& ${LeadingStateCell}`]: {
            strokeNoRight: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoRight: colors.strokeBrandDark,
            },
          },

          [`& ${TrailingStateCell}`]: {
            strokeNoLeft: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoLeft: colors.strokeBrandDark,
            },
          },
        },
      },
      false: {},
    },
    isNested: {
      true: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      isSelected: false,
      isNested: true,
      isNavigable: true,
      css: {
        [selectors.hover]: {
          index: 2,

          [`& ${CellInnerBase}`]: {
            background: colors.bgApplicationLight,
            strokeTopBottom: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              background: colors.bgApplicationDark,
              strokeTopBottom: colors.strokeNeutralDark,
            },
          },

          [`& ${StateCell}`]: {
            background: colors.bgApplicationLight,

            [darkThemeSelector]: {
              background: colors.bgApplicationDark,
            },
          },

          [`& ${LeadingStateCell}`]: {
            strokeNoRight: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              strokeNoRight: colors.strokeNeutralDark,
            },
          },

          [`& ${TrailingStateCell}`]: {
            strokeNoLeft: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              strokeNoLeft: colors.strokeNeutralDark,
            },
          },
        },
      },
    },
  ],
});

const BodyRowBase = styled(RowBase, {
  [selectors.hover]: {
    index: 2,

    [`& ${CellInnerBase}`]: {
      background: colors.bgNeutralLight,
      strokeTopBottom: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
        strokeTopBottom: colors.strokeNeutralDark,
      },
    },

    [`& ${StateCell}`]: {
      background: colors.bgNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
      },
    },

    [`& ${LeadingStateCell}`]: {
      strokeNoRight: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoRight: colors.strokeNeutralDark,
      },
    },

    [`& ${TrailingStateCell}`]: {
      strokeNoLeft: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoLeft: colors.strokeNeutralDark,
      },
    },
  },
});

interface TableRowProps {
  children?: React.ReactNode | undefined;
  isNavigable?: boolean;
  isSelected?: boolean;
}

type RowSelected = boolean;
const RowSelectedContext = createContext<RowSelected | undefined>(undefined);
export const RowSelectedProvider = RowSelectedContext.Provider;
export const useRowSelected = (
  controlledValue?: RowSelected,
  defaultValue: RowSelected = false,
) => {
  const rowSelected = useContext(RowSelectedContext);
  return controlledValue ?? rowSelected ?? defaultValue;
};

function RowInner<Tag extends React.ElementType>(
  { isNavigable, isSelected, ...props }: PolymorphicComponentProps<Tag, TableRowProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <BodyRowBase ref={ref} {...props} isNavigable={isNavigable} isSelected={isSelected}>
      <RowSelectedProvider value={isSelected}>{props.children}</RowSelectedProvider>
    </BodyRowBase>
  );
}

const Row = React.forwardRef(RowInner) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableRowProps
>;

const HeadRowBase = styled(RowBase, {
  position: 'relative',
  zIndex: 0,
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  '&[data-is-pinned="true"]': {
    zIndex: 5,
  },
});

type HeadRowProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

function HeadRowInner(props: HeadRowProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const innerRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
      threshold: [1],
    });

    if (innerRef.current) {
      observer.observe(innerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return <HeadRowBase {...props} ref={mergeRefs([ref, innerRef])} data-is-pinned={isPinned} />;
}

const HeadRow = React.forwardRef<HTMLDivElement, HeadCellProps>(HeadRowInner);

const HeadCellBase = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 2,
  display: 'table-cell',
  padding: '$6 $12',
  backgroundColor: colors.bgApplicationLight,
  strokeBottom: colors.strokeNeutralLight,
  cursor: 'pointer',
  fontWeight: fontWeights.bold,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeBottom: colors.strokeNeutralDark,
  },

  variants: {
    internal: {
      true: {
        color: '$$internalBodyLight',

        [darkThemeSelector]: {
          color: '$$internalBodyDark',
        },
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    isSorted: {
      true: {
        zIndex: 2,
      },
      false: {
        zIndex: 1,
      },
    },
  },
  compoundVariants: [
    {
      isNested: true,
      css: {
        backgroundColor: colors.bgNeutralLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgNeutralDark,
        },
      },
    },
  ],
});

const HeadCellSort = styled('div', {
  position: 'relative',
  width: 0,
  height: '$10',

  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

const HeadCellSortIcon = styled(Icon, {
  display: 'flex',
  marginLeft: '$4',
});

const HeadCellContents = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },
});

type HeadCellProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    alignment?: 'start' | 'center' | 'end';
    hideSortIcon?: boolean;
    internal?: boolean;
    isNested?: boolean;
    sortDirection?: 'asc' | 'desc' | boolean;
  }
>;

function HeadCellInner(
  { alignment, children, sortDirection, hideSortIcon, ...props }: HeadCellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <HeadCellBase isSorted={!!sortDirection} {...props} ref={ref}>
      <HeadCellContents alignment={alignment}>
        {children}
        {(!hideSortIcon || !!sortDirection) && (
          <HeadCellSort isVisible={!!sortDirection}>
            <HeadCellSortIcon
              icon={sortDirection === 'asc' ? 'chevron-up' : 'chevron-down'}
              size={space(10)}
            />
          </HeadCellSort>
        )}
      </HeadCellContents>
    </HeadCellBase>
  );
}

const HeadCell = React.forwardRef<HTMLDivElement, HeadCellProps>(HeadCellInner);

export const Table = {
  Main,
  Head,
  HeadRow,
  HeadCell,
  Body,
  Row,
  Cell,
  BufferCell,
  StateCell,
  LeadingStateCell,
  TrailingStateCell,
};
