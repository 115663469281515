import React from 'react';

import type { GutterProp } from '../../common/sizing';
import type { InternalProp } from '../../utilities/useInternal';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { InternalProvider, useInternal } from '../../utilities/useInternal';
import { Badge } from '../Badge/Badge';

type SummaryListPairs = {
  label: React.ReactNode;
  value: React.ReactNode;
  internal?: boolean;
};

const SummaryListKeyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '$6',
  width: '120px',
  minWidth: '120px',

  '@notDesktop': {
    minHeight: '$24',
  },

  '@desktop': {
    minHeight: '$20',
  },
});

const SummaryListKeyLabel = styled('div', Small, {
  minWidth: 'fit-content',
  flex: 0,
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const SummaryListKeyTrail = styled('div', {
  flex: 1,
  width: '100%',
  height: '1px',
  borderRadius: '99em',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

export type SummaryListKeyProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: InternalProp;
};

export function SummaryListKey({ children, internal, ...remaining }: SummaryListKeyProps) {
  const isInternal = useInternal(internal);
  return (
    <SummaryListKeyContainer {...remaining}>
      <SummaryListKeyLabel>{children}</SummaryListKeyLabel>
      <SummaryListKeyTrail />
      {isInternal && (
        <Badge internal size="small">
          Internal
        </Badge>
      )}
    </SummaryListKeyContainer>
  );
}

export const SummaryListValue = styled('div', Body, {
  display: 'flex',
  width: '100%',
  // Allows for ellipsis on their children.
  minWidth: 0,
  maxWidth: '700px',
  color: colors.headingNeutralLight,
  wordBreak: 'break-word',

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  '@notDesktop': {
    minHeight: '$24',
  },

  '@desktop': {
    minHeight: '$20',
  },
});

export const SummaryListRowContainer = styled('div', {
  hStack: '$8',
  alignItems: 'flex-start',
  minHeight: '$20',
  variants: {
    variant: {
      negative: {
        [`${SummaryListKeyLabel}`]: {
          color: colors.bodyNegativeLight,
          [darkThemeSelector]: {
            color: colors.bodyNegativeDark,
          },
        },

        [`${SummaryListKeyTrail}`]: {
          backgroundColor: colors.strokeNegativeLight,
          [darkThemeSelector]: {
            backgroundColor: colors.strokeNegativeDark,
          },
        },

        [`${SummaryListValue}`]: {
          color: colors.bodyNegativeLight,
          [darkThemeSelector]: {
            color: colors.bodyNegativeDark,
          },
        },
      },
      attention: {
        [`${SummaryListKeyLabel}`]: {
          color: colors.bodyAttentionLight,
          [darkThemeSelector]: {
            color: colors.bodyAttentionDark,
          },
        },
        [`${SummaryListKeyTrail}`]: {
          backgroundColor: colors.strokeAttentionLight,
          [darkThemeSelector]: {
            backgroundColor: colors.strokeAttentionDark,
          },
        },
        [`${SummaryListValue}`]: {
          color: colors.bodyAttentionLight,
          [darkThemeSelector]: {
            color: colors.bodyAttentionDark,
          },
        },
      },
    },
  },
});

export type SummaryListRowProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Set if the row has a special variant like `attention` or `negative` to highlight an issue.
   */
  variant?: 'attention' | 'negative';
};

export function SummaryListRow({ children, internal, variant, ...remaining }: SummaryListRowProps) {
  return (
    <InternalProvider value={internal}>
      <SummaryListRowContainer variant={variant} {...remaining}>
        {children}
      </SummaryListRowContainer>
    </InternalProvider>
  );
}

const SummaryListGroupLabel = styled(Small, {
  display: 'flex',
  fontWeight: fontWeights.bold,
});

const SummaryListGroupItems = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
});

const SummaryListGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
});

export type SummaryListGroupProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Provide a label for the group with `label`.
   */
  label?: React.ReactNode;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairs[];
};

export function SummaryListGroup({ children, label, pairs, ...remaining }: SummaryListGroupProps) {
  return (
    <SummaryListGroupContainer {...remaining}>
      <SummaryListGroupLabel>{label}</SummaryListGroupLabel>
      <SummaryListGroupItems>
        {children}
        {pairs &&
          pairs.map((pair, index) => {
            const key = index;
            return (
              <SummaryListRow internal={pair.internal} key={`summaryList-${key}`} {...pair}>
                {pair.label && <SummaryListKey>{pair.label}</SummaryListKey>}
                {pair.value && <SummaryListValue>{pair.value}</SummaryListValue>}
              </SummaryListRow>
            );
          })}
      </SummaryListGroupItems>
    </SummaryListGroupContainer>
  );
}

export const SummaryListContainer = styled('div', {
  variants: {
    display: {
      flex: {
        display: 'flex',
        flexDirection: 'column',
        gap: sizing.contentEnds,
      },
      grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, minmax(0, min-content))',
        gap: sizing.contentSides,
      },
    },
    gutter: {
      all: {
        padding: sizing.contentSquish,
      },
      vertical: {
        padding: sizing.contentEndsOnly,
      },
      horizontal: {
        padding: sizing.contentSidesOnly,
      },
      top: {
        paddingTop: sizing.contentEnds,
      },
      right: {
        paddingRight: sizing.contentSides,
      },
      bottom: {
        paddingBottom: sizing.contentEnds,
      },
      left: {
        paddingLeft: sizing.contentSides,
      },
      none: {},
    },
  },
});

export type SummaryListProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the display type of the summary list.
   */
  display?: 'flex' | 'grid';
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairs[];
};

export function SummaryList({
  children,
  display = 'flex',
  internal,
  gutter = 'all',
  pairs,
  ...remaining
}: SummaryListProps) {
  return (
    <InternalProvider value={internal}>
      <SummaryListContainer display={display} gutter={gutter} {...remaining}>
        {children}
        {pairs &&
          pairs.map((pair, index) => {
            const key = index;
            return (
              <SummaryListRow internal={pair.internal} key={`summaryList-${key}`} {...pair}>
                {pair.label && <SummaryListKey>{pair.label}</SummaryListKey>}
                {pair.value && <SummaryListValue>{pair.value}</SummaryListValue>}
              </SummaryListRow>
            );
          })}
      </SummaryListContainer>
    </InternalProvider>
  );
}
