import React from 'react';

import type { GutterProp } from '../../common/sizing';
import type { PaneHeaderProps } from '../Pane/Pane';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { LayoutDrawer } from '../Layout/Layout';
import { PaneHeader } from '../Pane/Pane';

export type DrawerHeaderProps = PaneHeaderProps;

export function DrawerHeader({ ...remaining }: DrawerHeaderProps) {
  return <PaneHeader {...remaining} />;
}

export type DrawerContentProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
};

const DrawerContentContainer = styled('div', {
  vStack: '$12',
  alignItems: 'stretch',
  minWidth: 0,
  maxHeight: '100%',
  overflow: 'auto',

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '-0.5px',
    height: '$1',
    background: colors.strokeApplicationLight,
    borderRadius: '$2',

    [darkThemeSelector]: {
      background: colors.strokeApplicationDark,
    },
  },

  variants: {
    gutter: {
      all: {
        padding: sizing.squish,
      },
      vertical: {
        padding: sizing.endsOnly,
      },
      horizontal: {
        padding: sizing.sidesOnly,
      },
      top: {
        paddingTop: sizing.ends,
      },
      right: {
        paddingRight: sizing.sides,
      },
      bottom: {
        paddingBottom: sizing.ends,
      },
      left: {
        paddingLeft: sizing.sides,
      },
      none: {},
    },
  },
  defaultVariants: {
    gutter: 'all',
  },
});

export function DrawerContent({ children, gutter, ...remaining }: DrawerContentProps) {
  return (
    <DrawerContentContainer gutter={gutter} {...remaining}>
      {children}
    </DrawerContentContainer>
  );
}

DrawerContent.displayName = 'DrawerContent';

const DrawerFooterStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$8',
});

const DrawerFooterEnd = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$8',
});

const DrawerFooterContainer = styled('div', {
  position: 'sticky',
  bottom: 0,
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  hStack: '$8',
  width: '100%',
  paddingX: '$20',
  overflow: 'auto',
  boxShadow: 'none',
  whiteSpace: 'nowrap',

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '-0.5px',
    height: '$1',
    background: colors.strokeApplicationLight,
    borderRadius: '$2',

    [darkThemeSelector]: {
      background: colors.strokeApplicationDark,
    },
  },

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '$44',
  },

  variants: {
    gutter: {
      all: {
        '&:before': {
          left: '$20',
          right: '$20',
        },
      },
      vertical: {},
      horizontal: {
        '&:before': {
          left: '$20',
          right: '$20',
        },
      },
      none: {
        '&:before': {
          left: 0,
          right: 0,
        },
      },
    },
  },
  defaultVariants: {
    gutter: 'all',
  },
});

export type DrawerFooterProps = {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: 'all' | 'horizontal' | 'none' | 'vertical';
};

export function DrawerFooter({
  gutter,
  actions,
  secondaryActions,
  ...remaining
}: DrawerFooterProps) {
  return (
    <DrawerFooterContainer gutter={gutter} {...remaining}>
      <DrawerFooterStart>{secondaryActions}</DrawerFooterStart>
      <DrawerFooterEnd>{actions}</DrawerFooterEnd>
    </DrawerFooterContainer>
  );
}

export const Drawer = styled(LayoutDrawer, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100%',
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    boxShadow: shadows.layerDark,
  },

  '& form': {
    // This allows for the use of a `<form>` inside of `DialogRender`
    // without affecting the flexbox of the children.
    display: 'contents',
  },

  '@notDesktop': {
    width: '100%',
    minWidth: '100%',
  },

  '@desktop': {
    width: '360px',
    minWidth: '360px',
  },

  '@notMobile': {
    position: 'relative',
    zIndex: 250,
  },
});
