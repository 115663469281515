import React from 'react';

import type { ControlSize, ControlVariant } from '../shared/types';
import { ControlSizeProvider } from '../../common/control_size';
import { ControlVariantProvider } from '../../common/control_variant';
import { BaseInputContainer } from '../../components/BaseInput/BaseInput';
import { SelectContainer, SelectTrigger } from '../../components/Select/Select';
import { styled } from '../../stitches.config';
import { BaseButton } from '../Button/Button';

const resetChildSelector = `& ${BaseButton}:not(:first-child,:last-child), & ${SelectContainer}:not(:first-child,:last-child) ${SelectTrigger}, & ${BaseInputContainer}:not(:first-child,:last-child)`;
const firstChildSelector = `& ${BaseButton}:first-child:not(:last-child), & ${SelectContainer}:first-child:not(:last-child) ${SelectTrigger} button, & > ${SelectTrigger}:first-child:not(:last-child), & ${BaseInputContainer}:first-child:not(:last-child)`;
const lastChildSelector = `& ${BaseButton}:last-child:not(:first-child), & ${SelectContainer}:last-child:not(:first-child) ${SelectTrigger}, & > ${SelectTrigger}:last-child:not(:first-child), & ${BaseInputContainer}:last-child:not(:first-child)`;

const ControlGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    relation: {
      joined: {
        [resetChildSelector]: {
          borderRadius: 0,
        },
      },
      separate: {
        gap: '$6',
      },
    },
    size: {
      small: {},
      medium: {},
      large: {},
      'x-large': {},
    },
  },

  compoundVariants: [
    {
      relation: 'separate',
      size: 'small',
      css: {
        gap: '$6',
      },
    },
    {
      relation: 'separate',
      size: 'medium',
      css: {
        gap: '$8',
      },
    },
    {
      relation: 'separate',
      size: 'large',
      css: {
        gap: '$10',
      },
    },
    {
      relation: 'separate',
      size: 'x-large',
      css: {
        gap: '$12',
      },
    },
    {
      relation: 'joined',
      size: 'small',
      css: {
        [firstChildSelector]: {
          borderRadius: '$8 0 0 $8',
        },

        [lastChildSelector]: {
          borderRadius: '0 $8 $8 0',
        },
      },
    },
    {
      relation: 'joined',
      size: 'medium',
      css: {
        [firstChildSelector]: {
          borderRadius: '$8 0 0 $8',
        },

        [lastChildSelector]: {
          borderRadius: '0 $8 $8 0',
        },
      },
    },
    {
      relation: 'joined',
      size: 'large',
      css: {
        [firstChildSelector]: {
          borderRadius: '$10 0 0 $10',
        },

        [lastChildSelector]: {
          borderRadius: '0 $10 $10 0',
        },
      },
    },
    {
      relation: 'joined',
      size: 'x-large',
      css: {
        [firstChildSelector]: {
          borderRadius: '$10 0 0 $10',
        },

        [lastChildSelector]: {
          borderRadius: '0 $10 $10 0',
        },
      },
    },
  ],
});

export type ControlGroupProps = {
  children: React.ReactNode;
  size?: ControlSize;
  relation?: 'joined' | 'separate';
  variant?: ControlVariant;
};

export function ControlGroup({
  children,
  size = 'medium',
  relation = 'joined',
  variant,
  ...remaining
}: ControlGroupProps) {
  return (
    <ControlGroupContainer size={size} relation={relation} {...remaining}>
      <ControlSizeProvider value={size}>
        <ControlVariantProvider value={variant}>{children}</ControlVariantProvider>
      </ControlSizeProvider>
    </ControlGroupContainer>
  );
}
