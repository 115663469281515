import type { ComponentProps } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { GutterProp } from '../../common/sizing';
import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { backgrounds } from '../../common/backgrounds';
import { ControlSizeProvider } from '../../common/control_size';
import { sizing } from '../../common/sizing';
import { Tabs } from '../../components/Tabs/Tabs';
import { Button } from '../../controls/Button/Button';
import { CloseButton } from '../../controls/CloseButton/CloseButton';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Subheading } from '../../text/Subheading';
import { space } from '../../utilities/shared/sizes';
import { VStack } from '../../utilities/Stack/VStack';
import { useViewport } from '../../utilities/useViewport';
import { LayoutPane } from '../Layout/Layout';

const PaneHRule = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '0',

  '&:before': {
    content: '',
    display: 'block',
    width: '100%',
    height: '1px',
    marginTop: '-0.5px',
    marginRight: sizing.sides,
    marginLeft: sizing.sides,
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

export type PaneHeaderProps = {
  /**
   * Provide any actions for your pane.
   */
  actions?: React.ReactNode;
  /**
   * Inline a back button, pass in the onClick event on this prop.
   */
  back?: () => void;
  /**
   * Show relevant badges alongside the `heading`.
   */
  badges?: React.ReactNode;
  closeButtonAriaLabel?: string;
  /**
   * Provide a handler and opt into adding a close icon to the right.
   */
  onClose?: React.ReactEventHandler;
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Provide an icon for your pane.
   */
  icon?: IconName;
  id?: string;
  /**
   * Allows for passing in more custom icon components overwrite the base `icon` prop.
   */
  illustration?: React.ReactNode;
  /**
   * Provide any subtabs in addition to tabs.
   */
  subtabs?: React.ReactNode;
  /**
   * Allows for a select interface to switch between objects.
   */
  switcher?: React.ReactNode;
  /**
   * Provide any actions for your currently active tab.
   */
  tabActions?: React.ReactNode;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: React.ReactNode;
};

const PaneHeaderVRule = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '0',
  height: '100%',

  '&:before': {
    content: '',
    position: 'absolute',
    display: 'block',
    width: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },

    '@notDesktop': {
      height: '$16',
    },

    '@desktop': {
      height: '$12',
    },
  },
});

const PaneHeaderIcon = styled(Icon, {
  color: colors.gray700,
  [darkThemeSelector]: {
    color: colors.gray50,
  },
});

const PaneHeaderHeading = styled(Subheading, {
  maxWidth: '100%',
  truncate: true,
  color: colors.headingNeutralLight,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
});

const PaneHeaderLockUp = styled('div', {
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const PaneHeaderBadges = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const PaneHeaderStart = styled('div', {
  minWidth: 0,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',

  '@notDesktop': {
    gap: '$12',
    minHeight: '$52',
  },

  '@desktop': {
    gap: '$10',
    minHeight: '$44',
  },
});

const PaneHeaderEnd = styled('div', {
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '@notDesktop': {
    minHeight: '$52',
    gap: '$8',
  },

  '@desktop': {
    minHeight: '$44',
    gap: '$6',
  },
});

const PaneHeaderTop = styled('div', {
  minWidth: 0,
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$12',
  padding: sizing.sidesOnly,
});

const PaneHeaderBottom = styled('div', {
  minWidth: 0,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: sizing.sidesOnly,
  overflowX: 'auto',

  '@notDesktop': {
    gap: '$16',
    minHeight: '$52',
  },

  '@desktop': {
    gap: '$16',
    minHeight: '$44',
  },
});

const PaneHeaderTabsParent = styled(Tabs);

const PaneHeaderTabsChildren = styled(Tabs, {
  position: 'relative',
  '&:not(:first-child)::before': {
    content: '',
    position: 'absolute',
    top: '50%',
    left: '-9px',
    display: 'flex',
    width: '2px',
    minWidth: '2px',
    height: '$16',
    minHeight: '$16',
    marginTop: '-8px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

const PaneHeaderContainer = styled('div', {
  position: 'relative',
  zIndex: 2,
  minWidth: 0,
  gridArea: 'titlebar',
  display: 'flex',
  flexDirection: 'column',

  variants: {
    hasBothRows: {
      true: {
        '@notDesktop': {
          minHeight: '$104',
        },

        '@desktop': {
          minHeight: '$88',
        },
      },
      false: {
        '@notDesktop': {
          minHeight: '$52',
        },

        '@desktop': {
          minHeight: '$44',
        },
      },
    },
  },
});

export function PaneHeader({
  actions,
  back,
  badges,
  closeButtonAriaLabel = 'Close',
  onClose,
  heading,
  icon,
  illustration,
  subtabs,
  switcher,
  tabActions,
  tabs,
  ...remaining
}: PaneHeaderProps) {
  const { breakpoint } = useViewport();
  const hasStart = icon || heading || switcher;
  const hasEnd = actions || onClose;
  const hasTop = hasStart || hasEnd;
  const hasBottom = tabs || subtabs || tabActions;
  const hasBothRows = hasTop && hasBottom;

  return (
    <PaneHeaderContainer hasBothRows={!!hasBothRows} {...remaining}>
      {hasTop && (
        <PaneHeaderTop>
          <PaneHeaderStart>
            {hasStart && (
              <>
                {back && (
                  <Button
                    onClick={back}
                    variant="secondary"
                    size={breakpoint === 'desktop' ? 'small' : 'medium'}
                    arrangement="hidden-label"
                    icon="chevron-left"
                  >
                    Back
                  </Button>
                )}
                {back && hasStart && <PaneHeaderVRule />}
                {hasStart && (
                  <PaneHeaderLockUp>
                    {illustration}
                    {!illustration && icon && <PaneHeaderIcon icon={icon} size={space(16)} />}
                    {!switcher && heading && <PaneHeaderHeading>{heading}</PaneHeaderHeading>}
                    {switcher}
                  </PaneHeaderLockUp>
                )}
                {(icon || heading) && badges && <PaneHeaderVRule />}
                {badges && (
                  <ControlSizeProvider value={breakpoint === 'desktop' ? 'small' : 'medium'}>
                    <PaneHeaderBadges>{badges}</PaneHeaderBadges>
                  </ControlSizeProvider>
                )}
              </>
            )}
          </PaneHeaderStart>
          <PaneHeaderEnd>
            {hasEnd && (
              <>
                {actions && (
                  <ControlGroup size="small" relation="separate">
                    {actions}
                  </ControlGroup>
                )}
                {onClose && <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel} />}
              </>
            )}
          </PaneHeaderEnd>
        </PaneHeaderTop>
      )}
      {hasTop && hasBottom && <PaneHRule />}
      {hasBottom && (
        <PaneHeaderBottom>
          <PaneHeaderStart>
            {tabs && <PaneHeaderTabsParent>{tabs}</PaneHeaderTabsParent>}
            {subtabs && <PaneHeaderTabsChildren>{subtabs}</PaneHeaderTabsChildren>}
          </PaneHeaderStart>
          <PaneHeaderEnd>
            {tabActions && (
              <ControlGroup size="small" relation="separate">
                {tabActions}
              </ControlGroup>
            )}
          </PaneHeaderEnd>
        </PaneHeaderBottom>
      )}
      <PaneHRule />
    </PaneHeaderContainer>
  );
}

export type PaneContentProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Set whether the background should be checkered or not.
   */
  background?: 'default' | 'checkered';
} & ComponentProps<typeof PaneContentContainer>;

const PaneContentContainer = styled(VStack, {
  position: 'relative',
  minWidth: 0,
  height: '100%',
  flexGrow: 1,
  gridArea: 'content',
  overflow: 'auto',

  '&::before': {
    position: 'absolute',
    top: '-0.5px',
    left: sizing.sides,
    right: sizing.sides,
    content: '',
    display: 'block',
    height: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    gutter: {
      all: {
        padding: sizing.squish,
      },
      vertical: {
        padding: sizing.endsOnly,
      },
      horizontal: {
        padding: sizing.sidesOnly,
      },
      top: {
        paddingTop: sizing.ends,
      },
      right: {
        paddingRight: sizing.sides,
      },
      bottom: {
        paddingBottom: sizing.ends,
      },
      left: {
        paddingLeft: sizing.sides,
      },
      none: {},
    },
    background: {
      checkered: {
        background: backgrounds.dottedLight,
        backgroundColor: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: backgrounds.dottedDark,
          backgroundColor: colors.bgApplicationDark,
        },
      },
      default: {},
    },
  },
  defaultVariants: {
    gutter: 'none',
  },
});

export const PaneContent = React.forwardRef(
  <Tag extends React.ElementType>(
    { background, children, gutter, ...remaining }: PaneContentProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <PaneContentContainer gutter={gutter} background={background} {...remaining} ref={ref}>
      {children}
    </PaneContentContainer>
  ),
);

export const PaneFooter = styled('div', {
  position: 'relative',
  zIndex: 2,
  minWidth: 0,
  gridArea: 'footer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: sizing.sidesOnly,
  backgroundColor: colors.bgApplicationLight,
  strokeTop: colors.strokeApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeTop: colors.strokeApplicationDark,
  },

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '$44',
  },
});

export const Pane = styled(LayoutPane, {
  display: 'grid',
  gridTemplateRows: 'fit-content(100%) 1fr fit-content(100%)',
  gridTemplateAreas: '"titlebar" "content" "footer"',
  width: '100%',
  height: '100%',

  '@notMobile': {
    position: 'relative',
    zIndex: 200,
  },

  variants: {
    variant: {
      adjusted: {
        width: '100%',
        margin: '0 auto',
        '@mobile': {
          height: '100%',
          [`& ${PaneContentContainer}`]: {
            padding: '$16',
          },
        },
        '@notMobile': {
          minWidth: '480px',
          maxWidth: '760px',
          padding: '$32 $32 0',
        },
      },
      full: {},
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});
