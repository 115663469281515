/* eslint-disable react/prop-types, react/no-unused-prop-types */
import type { AriaTextFieldProps } from '@react-types/textfield';
import type { InputHTMLAttributes } from 'react';
import React, { useRef } from 'react';
import { useTextField } from 'react-aria';

import type { ControlSize } from '../../controls/shared/types';
import type { InputSharedProps } from '../BaseInput/BaseInput';
import { BaseInput } from '../BaseInput/BaseInput';

type RemappedAriaTextFieldProps = Omit<AriaTextFieldProps, 'isDisabled'> & {
  disabled?: AriaTextFieldProps['isDisabled'];
};

export interface TextInputProps extends InputSharedProps, RemappedAriaTextFieldProps {
  invalid?: boolean;
  controlSize?: ControlSize;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const TextInput = React.forwardRef<HTMLLabelElement, TextInputProps>((props, ref) => {
  const {
    invalid,
    icon,
    prefix,
    suffix,
    width,
    maxWidth,
    minWidth,
    controlSize,
    disabled = false,
    inputProps,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps: ariaInputProps } = useTextField({ ...props, isDisabled: disabled }, inputRef);
  const finalInputProps = { ...ariaInputProps, ...inputProps };

  return (
    <BaseInput
      ref={ref}
      inputRef={inputRef}
      inputProps={finalInputProps}
      invalid={invalid}
      disabled={disabled}
      icon={icon}
      prefix={prefix}
      suffix={suffix}
      controlSize={controlSize}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
    />
  );
});
