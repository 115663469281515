import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { ViewportProvider } from '../../utilities/useViewport';

export const LayoutContainer = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: colors.bgNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
  },

  '@mobile': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content 1fr',
    gridTemplateAreas: '"banner" "dock" "page"',
    minWidth: '320px',
  },

  '@notMobile': {
    gridTemplateRows: 'min-content min-content 1fr',
    gridTemplateAreas: '"banner banner" "dock page" "dock page"',
  },

  '@tablet': {
    gridTemplateColumns: 'min-content minmax(0, 1fr)',
  },

  '@desktop': {
    gridTemplateColumns: 'min-content minmax(0, 1fr)',
  },
});

export type LayoutProps = {
  children?: React.ReactNode;
};

export function Layout({ children, ...remaining }: LayoutProps) {
  return (
    <ViewportProvider>
      <LayoutContainer {...remaining}>{children}</LayoutContainer>
    </ViewportProvider>
  );
}

export const LayoutBanner = styled('div', {
  position: 'relative',
  minWidth: 0,
  gridArea: 'banner',

  '@mobile': {
    zIndex: 250,
  },

  '@notMobile': {
    zIndex: 50,
  },
});

export const LayoutDock = styled('div', {
  position: 'relative',
  minWidth: 0,
  gridArea: 'dock',
  overflow: 'hidden',

  '@mobile': {
    zIndex: 250,
  },

  '@notMobile': {
    zIndex: 50,
  },

  variants: {
    collapsed: {
      true: {},
      false: {},
    },
  },
});

export const LayoutPage = styled('div', {
  position: 'relative',
  zIndex: 100,
  minWidth: 0,
  gridArea: 'page',
  display: 'grid',
  gridTemplateRows: '1fr',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.layerDark,
  },

  '@mobile': {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"pane"',
    strokeTop: colors.strokeApplicationLight,
    [darkThemeSelector]: {
      strokeTop: colors.strokeApplicationDark,
    },
  },

  '@notMobile': {
    gridTemplateColumns: 'minmax(0, 1fr) fit-content(100%)',
    gridTemplateAreas: '"pane drawer"',
  },

  [selectors.safari]: {
    borderTopRightRadius: '$10',
  },
});

export const LayoutPane = styled('div', {
  minWidth: 0,
  gridArea: 'pane',
  overflow: 'hidden',

  '&:only-child': {
    gridArea: 'pane / pane / drawer / drawer',
  },

  '@notDesktop': {
    '&:not(:only-child)': {
      display: 'none',
    },
  },
});

export const LayoutDrawer = styled('div', {
  minWidth: 0,
  gridArea: 'drawer',
  overflow: 'hidden',

  '@notDesktop': {
    gridArea: 'pane',
    width: '100%',
  },
});
