import { IconSVG } from 'atto-svgs';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  width: '100%',
});

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$4',
  background: colors.white,
  boxShadow: shadows.manufacturerLight,
  color: colors.gray600,
  flexShrink: 0,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    size: {
      small: {
        borderRadius: '$10',
        width: '$20',
        height: '$20',
      },
      medium: {
        borderRadius: '15px',
        width: '30px',
        height: '30px',
        padding: '$6',
      },
      large: {
        borderRadius: '20px',
        width: '$40',
        height: '$40',
      },
    },
  },
});

export interface ClientIconProps {
  className?: string;

  /**
   * Sets the size of the component.
   */
  size?: 'large' | 'medium' | 'small';
}

export function ClientIcon({ className, size = 'medium', ...remaining }: ClientIconProps) {
  return (
    <Container className={className} size={size} {...remaining}>
      <BaseSVG width={12} height={12} as={IconSVG.Client} />
    </Container>
  );
}
