import { colors, darkThemeSelector, fontWeights, styled } from '../stitches.config';

export const Text = styled('span', {
  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      justify: {
        textAlign: 'justify',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    decoration: {
      none: {
        textDecoration: 'none',
      },
      'line-through': {
        textDecoration: 'line-through',
      },
      underline: {
        textDecoration: 'underline',
      },
    },
    display: {
      block: {
        display: 'block',
      },
      flex: {
        display: 'flex',
      },
      inline: {
        display: 'inline',
      },
      'inline-block': {
        display: 'inline-block',
      },
      'inline-flex': {
        display: 'inline-flex',
      },
    },
    family: {
      brand: {
        fontFamily: '$title',
      },
      monospace: {
        fontFamily: '$mono',
        fontSize: '0.8rem', // Bumps text down due to the visual size difference between our sans and mono fonts.
      },
      regular: {
        fontFamily: '$sans',
      },
    },
    italicize: {
      italic: {
        fontStyle: 'italic',
      },
      regular: {
        fontStyle: 'normal',
      },
    },
    lineHeight: {
      12: {
        fontSize: '$12',
      },
      16: {
        fontSize: '$16',
      },
      20: {
        fontSize: '$20',
      },
      24: {
        fontSize: '$24',
      },
      28: {
        fontSize: '$28',
      },
      32: {
        fontSize: '$32',
      },
    },
    size: {
      11: {
        fontSize: '$11',
      },
      12: {
        fontSize: '$12',
      },
      13: {
        fontSize: '$13',
      },
      14: {
        fontSize: '$14',
      },
      16: {
        fontSize: '$16',
      },
      20: {
        fontSize: '$20',
      },
      24: {
        fontSize: '$24',
      },
    },
    transform: {
      capitalize: {
        textTransform: 'capitalize',
      },
      lowercase: {
        textTransform: 'lowercase',
      },
      none: {
        textTransform: 'none',
      },
      uppercase: {
        textTransform: 'uppercase',
      },
    },
    variant: {
      'diagonal-fractions': {
        fontVariantNumeric: 'diagonal-fractions',
      },
      lining: {
        fontVariantNumeric: 'lining-nums',
      },
      oldstyle: {
        fontVariantNumeric: 'oldstyle-nums',
      },
      ordinal: {
        fontVariantNumeric: 'ordinal',
      },
      proportional: {
        fontVariantNumeric: 'proportional-nums',
      },
      'slashed-zero': {
        fontVariantNumeric: 'slashed-zero',
      },
      'stacked-fractions': {
        fontVariantNumeric: 'stacked-fractions',
      },
      regular: {
        fontVariantNumeric: 'normal',
      },
      tabular: {
        fontVariantNumeric: 'tabular-nums',
      },
    },
    weight: {
      light: {
        fontWeight: fontWeights.light,
      },
      regular: {
        fontWeight: fontWeights.regular,
      },
      medium: {
        fontWeight: fontWeights.bold,
      },
      bold: {
        fontWeight: fontWeights.bold,
      },
    },
    whitespace: {
      normal: {
        whiteSpace: 'normal',
      },
      'no-wrap': {
        whiteSpace: 'nowrap',
      },
      pre: {
        whiteSpace: 'pre',
      },
      'pre-line': {
        whiteSpace: 'pre-line',
      },
      'pre-wrap': {
        whiteSpace: 'pre-wrap',
      },
    },
    wordBreak: {
      'break-all': {
        wordBreak: 'break-all',
      },
      'break-word': {
        wordBreak: 'break-word',
      },
      normal: {
        wordBreak: 'normal',
      },
    },

    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
});
