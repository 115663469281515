import type * as Polymorphic from '@radix-ui/react-polymorphic';
import { uniqueId } from 'lodash-es';
import React, { useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ProductIconName } from '../../assets/ProductIcon/ProductIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { ProductIcon } from '../../assets/ProductIcon/ProductIcon';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';

const SidebarTargetIcon = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',

  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const SidebarTargetLabel = styled(Body, {
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

const SidebarTargetLink = styled('a', FocusRing, {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.bodyNeutralDark,
  },

  '@notDesktop': {
    gap: '$8',
    padding: '$8 $12',
    borderRadius: '10px',
  },

  '@desktop': {
    gap: '$6',
    padding: '$4 $8',
    borderRadius: '$8',
  },

  [selectors.hover]: {
    zIndex: 2,
    backgroundColor: colors.bgNeutralLight,
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgNeutralDark,
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  [selectors.focus]: {
    zIndex: 4,
  },

  variants: {
    internal: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        zIndex: 3,
        [`&, ${selectors.hover}`]: {
          $$labelColor: colors.headingBrandLight,
          $$iconColor: colors.bodyBrandLight,
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          [darkThemeSelector]: {
            $$labelColor: colors.headingBrandDark,
            $$iconColor: colors.bodyBrandDark,
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        $$labelColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,

        [darkThemeSelector]: {
          $$labelColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
        },

        [selectors.hover]: {
          $$labelColor: colors.internalHeadingLight,
          $$iconColor: colors.internalBodyLight,

          [darkThemeSelector]: {
            $$labelColor: colors.internalHeadingDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
    {
      internal: true,
      isSelected: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.internalBgLight,
          strokeAll: colors.internalStrokeLight,

          [darkThemeSelector]: {
            backgroundColor: colors.internalBgDark,
            strokeAll: colors.internalStrokeDark,
          },
        },
      },
    },
  ],
});

const SidebarTargetLI = styled('li', {
  width: '100%',
});

export interface SidebarTargetProps {
  icon?: IconName;
  internal?: boolean;
  isSelected?: boolean;
  label?: React.ReactNode;
}

export const SidebarTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      icon,
      internal,
      isSelected,
      label,
      ...props
    }: PolymorphicComponentProps<Tag, SidebarTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <SidebarTargetLI role="menu-item">
      <SidebarTargetLink
        as={as}
        ref={forwardedRef}
        aria-label={`${label}`}
        {...props}
        internal={internal}
        isSelected={isSelected}
      >
        {icon && <SidebarTargetIcon icon={icon} />}
        <SidebarTargetLabel>{label}</SidebarTargetLabel>
      </SidebarTargetLink>
    </SidebarTargetLI>
  ),
) as Polymorphic.ForwardRefComponent<React.ElementType, SidebarTargetProps>;

const SidebarGroupChevron = styled(Icon, {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const SidebarGroupLabel = styled(Small, {
  fontWeight: fontWeights.bold,
});

const SidebarGroupChain = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: colors.strokeNeutralLight,
  borderRadius: '$8',
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

const SidebarGroupHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: '$16',

  '@notDesktop': {
    gap: '$8',
    padding: '$0 $12 $4',
  },

  '@desktop': {
    gap: '$6',
    padding: '$0 $8 $4',
  },
});

const SidebarGroupItemsContainer = styled('ul', {
  vStack: '$0',
  alignItems: 'stretch',
  width: '100%',
  variants: {
    collapsed: {
      true: {
        display: 'none',
      },
      false: {
        display: 'flex',
      },
    },
  },
});

type SidebarGroupItemsProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  id?: string;
};

export function SidebarGroupItems({
  children,
  collapsed,
  id,
  ...remaining
}: SidebarGroupItemsProps) {
  return (
    <SidebarGroupItemsContainer id={id} role="menu" collapsed={collapsed} {...remaining}>
      {children}
    </SidebarGroupItemsContainer>
  );
}

const SidebarGroupContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$4',
});

type SidebarGroupProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  label?: React.ReactNode;
  onClick?: (event: any) => void;
  id?: string;
};

export function SidebarGroup({
  children,
  collapsed,
  onClick,
  label,
  id,
  ...remaining
}: SidebarGroupProps) {
  const [groupID] = useState(uniqueId('sgi-'));
  return (
    <SidebarGroupContainer role="group" {...remaining}>
      {(onClick || label) && (
        <SidebarGroupHeader
          aria-controls={onClick && groupID}
          aria-expanded={!(onClick && collapsed)}
          role={onClick && 'button'}
          onClick={onClick}
          id={`${groupID}_${id}`}
        >
          {onClick && (
            <SidebarGroupChevron
              icon={collapsed ? 'chevron-right' : 'chevron-down'}
              size={space(8)}
            />
          )}
          {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
          <SidebarGroupChain />
        </SidebarGroupHeader>
      )}
      <SidebarGroupItems aria-labelledby={`${groupID}_${id}`} id={groupID} collapsed={collapsed}>
        {children}
      </SidebarGroupItems>
    </SidebarGroupContainer>
  );
}

const SidebarGroupsContainer = styled('ul', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  width: '100%',
  height: '100%',
  padding: sizing.contentSquish,
  overflowY: 'auto',

  '@mobile': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$12',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$12',
    },
  },

  '@tablet': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$12',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$12',
    },
  },

  '@desktop': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$8',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$8',
    },
  },
});

type SidebarGroupsProps = {
  children?: React.ReactNode;
  id?: string;
};

export function SidebarGroups({ children, ...remaining }: SidebarGroupsProps) {
  return (
    <SidebarGroupsContainer role="menu" {...remaining}>
      {children}
    </SidebarGroupsContainer>
  );
}

const SidebarEndsContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',

  '@mobile': {
    padding: `$8 ${sizing.contentSides}`,
  },

  '@notMobile': {
    padding: sizing.contentSquish,
  },
});

const SidebarStartContainer = styled(SidebarEndsContainer, {
  '&::after': {
    position: 'absolute',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    content: '',
    display: 'block',
    height: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    hasHeading: {
      true: {
        gap: '$8',
        padding: `$8 ${sizing.contentSides} ${sizing.contentEnds}`,
      },
      false: {},
    },
  },
});

type SidebarStartProps = {
  children?: React.ReactNode;
  hasHeading?: boolean;
};

export function SidebarStart({ children, hasHeading, ...remaining }: SidebarStartProps) {
  return (
    <SidebarStartContainer hasHeading={hasHeading} {...remaining}>
      {children}
    </SidebarStartContainer>
  );
}

const SidebarEndContainer = styled(SidebarEndsContainer, {
  '&::before': {
    position: 'absolute',
    top: '-0.5px',
    right: sizing.contentSides,
    left: sizing.contentSides,
    content: '',
    display: 'block',
    height: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

type SidebarEndProps = {
  children?: React.ReactNode;
};

export function SidebarEnd({ children, ...remaining }: SidebarEndProps) {
  return <SidebarEndContainer {...remaining}>{children}</SidebarEndContainer>;
}

const SidebarHeaderIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$16',
  height: '$16',
});

const SidebarHeaderLabel = styled(Body);

const SidebarHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
  alignItems: 'center',

  '@mobile': {
    display: 'none',
  },
});

const SidebarContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  height: '100%',
  padding: `$4 0`,
  overflow: 'hidden',

  '@mobile': {
    width: '100%',
  },

  '@tablet': {
    width: '268px',
    minWidth: '268px',
  },

  '@desktop': {
    width: '240px',
    minWidth: '240px',
  },

  variants: {
    collapsed: {
      true: {
        '@mobile': {
          display: 'none',
        },
      },
      false: {},
    },
  },
});

type SidebarProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  end?: React.ReactNode;
  heading?: React.ReactNode;
  icon?: IconName;
  product?: ProductIconName;
  start?: React.ReactNode;
};

export function Sidebar({
  children,
  collapsed,
  end,
  heading,
  icon,
  product,
  start,
  ...remaining
}: SidebarProps) {
  return (
    <SidebarContainer collapsed={collapsed} {...remaining}>
      {(heading || start) && (
        <SidebarStart hasHeading={!!heading}>
          {heading && (
            <SidebarHeader>
              <SidebarHeaderIcon>
                {product ? (
                  <ProductIcon product={product} size={18} />
                ) : (
                  icon && <Icon icon={icon} size={space(16)} />
                )}
              </SidebarHeaderIcon>
              <SidebarHeaderLabel weight="bold">{heading}</SidebarHeaderLabel>
            </SidebarHeader>
          )}
          {start}
        </SidebarStart>
      )}
      {children}
      {end && <SidebarEnd>{end}</SidebarEnd>}
    </SidebarContainer>
  );
}
