import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { mergeProps, useFocus } from 'react-aria';
import useMeasure from 'react-use-measure';

import type { IconName } from '../../assets/Icon/Icon';
import type { ControlSize } from '../../controls/shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { ControlSizeProvider, useControlSize } from '../../common/control_size';
import { selectors, transitions } from '../../controls/shared/styles';
import {
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';

const iconColor = '$$iconColor';
const iconSize = '$$iconSize';
const inputColor = '$$inputColor';
const inputPadding = '$$inputPadding';
const lineHeight = '$$inputColor';
const placeholderColor = '$$placeholderColor';
const sidecarColor = '$$sidecarColor';
const sidecarGap = '$$sidecarGap';

export const BaseInputContainer = styled('label', {
  display: 'flex',
  background: colors.white,
  position: 'relative',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  [inputColor]: colors.gray800,
  [iconColor]: colors.gray700,
  [placeholderColor]: colors.gray500,
  [sidecarColor]: colors.gray700,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),
  transition: transitions.control,
  [selectors.focus]: {
    outline: 'none',
  },
  '&[data-is-focused]': {
    boxShadow: shadows.fieldFocusedLight,
  },
  [darkThemeSelector]: {
    background: colors.gray800,
    [inputColor]: colors.gray50,
    [iconColor]: colors.gray100,
    [placeholderColor]: colors.gray300,
    [sidecarColor]: colors.gray100,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),
    '&[data-is-focused]': {
      boxShadow: shadows.fieldFocusedDark,
    },
  },
  variants: {
    type: {
      none: {},
      text: {},
      tel: {},
      url: {},
      email: {},
      numeric: {},
      decimal: {},
      search: {},
    },
    size: {
      small: {
        [inputPadding]: '$space$4 $space$6',
        [sidecarGap]: '$space$4',
        [iconSize]: '$space$10',
        fontSize: '$12',
        [lineHeight]: '$16',
        maxHeight: '$24',
        borderRadius: '$8',
      },
      medium: {
        [inputPadding]: '$space$4 $space$8',
        [sidecarGap]: '$space$6',
        [iconSize]: '$space$12',
        fontSize: '$14',
        [lineHeight]: '$20',
        borderRadius: '$8',
      },
      large: {
        [inputPadding]: '$space$8 $space$12',
        [sidecarGap]: '$space$6',
        [iconSize]: '$space$12',
        fontSize: '$14',
        [lineHeight]: '$20',
        borderRadius: '$8',
      },
      'x-large': {
        [inputPadding]: '$space$10 $space$14',
        [sidecarGap]: '$space$6',
        [iconSize]: '$space$12',
        fontSize: '$16',
        [lineHeight]: '$24',
        borderRadius: '$8',
      },
    },
    invalid: {
      true: {
        strokeAll: fade(palette.controlStrokeBaseErrorLight, 0.8),
        [darkThemeSelector]: {
          strokeAll: fade(palette.controlStrokeBaseErrorDark, 0.8),
        },
      },
      false: {
        '&:not([data-is-focused]):hover': {
          strokeAll: fade(palette.controlStrokeBaseLight, 0.18),
          [darkThemeSelector]: {
            strokeAll: fade(palette.controlStrokeBaseDark, 0.66),
          },
        },
      },
    },
    isDisabled: {
      true: {
        background: colors.gray50,
        strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
        opacity: 0.5,
        pointerEvents: 'none',
        [darkThemeSelector]: {
          background: colors.gray900,
          strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      size: 'small',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'medium',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'large',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'x-large',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
  ],
});

const Input = styled('input', {
  outline: 'none',
  border: 'none',
  width: '100%',
  background: 'none',
  padding: inputPadding,
  lineHeight,
  truncate: true,
  color: inputColor,
  '&::placeholder': {
    color: placeholderColor,
  },
});

const Sidecar = styled('div', {
  hStack: sidecarGap,
  position: 'absolute',
  top: 0,
  bottom: 0,
  padding: inputPadding,
  color: sidecarColor,
});

const Prefix = styled(Sidecar, {
  left: 0,
});

const Suffix = styled(Sidecar, {
  right: 0,
});

const StyledIcon = styled(Icon, {
  width: iconSize,
  height: iconSize,
  color: iconColor,
});

export interface InputSharedProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  icon?: IconName;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
}

export interface BaseInputProps extends InputSharedProps {
  disabled?: boolean;
  invalid?: boolean;
  shouldApplyFocusStyles?: boolean;
  controlSize?: ControlSize;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  inputRef?: React.Ref<HTMLInputElement>;
  type?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
}

export const BaseInput = React.forwardRef<HTMLLabelElement, BaseInputProps>(
  (
    {
      disabled = false,
      invalid = false,
      shouldApplyFocusStyles = true,
      icon,
      prefix,
      suffix,
      width,
      maxWidth,
      minWidth,
      type,
      controlSize,
      inputProps = {},
      inputRef,
      ...remaining
    },
    ref,
  ) => {
    const size = useControlSize(controlSize, 'medium');
    const [isFocused, setIsFocused] = useState(false);
    const { focusProps } = useFocus({ onFocusChange: (value) => setIsFocused(value) });

    const [prefixContainerRef, { width: iconContainerWidth = 0 }] = useMeasure();
    const [suffixContainerRef, { width: sidecarContainerWidth = 0 }] = useMeasure();

    return (
      <BaseInputContainer
        ref={ref}
        role="presentation"
        size={size}
        type={type}
        invalid={invalid}
        isDisabled={disabled}
        data-is-focused={isFocused && shouldApplyFocusStyles ? '' : undefined}
        style={{ width, maxWidth, minWidth }}
        {...remaining}
      >
        {(icon || prefix) && (
          <ControlSizeProvider value="small">
            <Prefix ref={prefixContainerRef}>
              {icon && <StyledIcon icon={icon} />}
              {prefix}
            </Prefix>
          </ControlSizeProvider>
        )}
        <Input
          {...mergeProps(inputProps, focusProps)}
          ref={inputRef}
          style={{
            paddingLeft: iconContainerWidth > 0 ? iconContainerWidth : undefined,
            paddingRight: sidecarContainerWidth > 0 ? sidecarContainerWidth : undefined,
          }}
        />
        {suffix && (
          <ControlSizeProvider value="small">
            <Suffix ref={suffixContainerRef}>{suffix}</Suffix>
          </ControlSizeProvider>
        )}
      </BaseInputContainer>
    );
  },
);
