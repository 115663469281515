import type { FocusableElement } from '@react-types/shared';
import type { Node } from '@react-types/shared/src/collections';
import type { AriaComboBoxProps, AriaPopoverProps, AriaSelectProps } from 'react-aria';
import { useObjectRef } from '@react-aria/utils';
import * as React from 'react';
import { useMemo } from 'react';
import { HiddenSelect, useSelect } from 'react-aria';
import { DialogTrigger, Popover } from 'react-aria-components';
import { mergeRefs } from 'react-merge-refs';
import { useSelectState } from 'react-stately';
import useMeasure from 'react-use-measure';

import type { FromSingleSelectionProps } from '../../common/props';
import type { ControlSize } from '../../controls/shared/types';
import { type IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { useControlSize } from '../../common/control_size';
import { toSingleSelectionProps } from '../../common/props';
import { Button } from '../../controls/Button/Button';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { SelectTrigger, SelectValue } from '../Select/Select';
import { ComboBoxOverlay } from './ComboBoxOverlay';

const iconColor = '$$iconColor';
const iconSize = '$$iconSize';

const ComboBoxIcon = styled(Icon, {
  width: iconSize,
  height: iconSize,
  color: iconColor,
});

const ComboBoxPopover = styled(Popover, {
  overflow: 'auto',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',
  display: 'flex',
  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
});

interface ComboBoxProps<T> extends FromSingleSelectionProps<AriaSelectProps<T>> {
  icon?: IconName;
  invalid?: boolean;
  size?: ControlSize;
  defaultItems?: AriaComboBoxProps<T>['defaultItems'];
  placement?: AriaPopoverProps['placement'];
  maxWidth?: string | number;
  popoverMaxWidth?: string | number;
  canClearValue?: boolean;
  indeterminate?: boolean;
  actions?: React.ReactNode;
  /**
   * The minimum number of characters required to be input before showing results.
   * To limit number of items rendered in very large lists.
   */
  minSearchLength?: number;
  /**
   * Render a custom component for the selected item.
   */
  renderSelected?: (option: Node<T>) => React.ReactNode;
}

function ComboBoxInner<T extends object>(
  props: ComboBoxProps<T>,
  forwardRef: React.ForwardedRef<FocusableElement>,
) {
  const renamedProps = toSingleSelectionProps(props);
  const state = useSelectState({
    ...renamedProps,
    items: props.defaultItems ?? props.items,
  });
  const [measureRef, { width: inputWidth }] = useMeasure();
  const controlSize = useControlSize(props.size, 'medium');

  const { placement = 'bottom start', canClearValue = false, indeterminate } = renamedProps;

  const ref = useObjectRef(forwardRef);
  const { valueProps, triggerProps } = useSelect(renamedProps, state, ref);

  const selected = useMemo(() => {
    if (!state.selectedItem) return null;
    if (props.renderSelected) return props.renderSelected(state.selectedItem);
    return state.selectedItem.rendered;
  }, [props, state.selectedItem]);
  const selectTrigger = (
    <SelectTrigger
      {...triggerProps}
      ref={mergeRefs([ref, measureRef])}
      size={controlSize}
      css={{ width: '100%' }}
      isDisabled={props.disabled ?? false}
      type="button"
      onKeyDown={(e) => {
        if (triggerProps.isDisabled) return;

        const isAlphanumeric = e.key.length === 1 && e.key.match(/[a-z0-9]/i);

        if (isAlphanumeric || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter') {
          state.open();
        } else if (e.key === 'Backspace' && canClearValue) {
          state.setSelectedKey('');
        }
      }}
    >
      {props.icon && <ComboBoxIcon icon={props.icon} />}
      <SelectValue {...valueProps} size={controlSize}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {indeterminate ? (
          <>&ndash;</>
        ) : state.selectedItem ? (
          selected
        ) : (
          props.placeholder ?? 'Select an option'
        )}
      </SelectValue>
      <ComboBoxIcon icon="chevrons-vertical" />
    </SelectTrigger>
  );

  return (
    <DialogTrigger>
      <HiddenSelect
        isDisabled={props.disabled}
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      {state.selectedItem && canClearValue ? (
        <ControlGroup size={controlSize}>
          {selectTrigger}
          <Button
            type="button"
            icon="cross"
            variant="secondary"
            arrangement="hidden-label"
            condense
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              state.setSelectedKey('');
            }}
          >
            Clear selection
          </Button>
        </ControlGroup>
      ) : (
        selectTrigger
      )}
      <ComboBoxPopover
        triggerRef={ref}
        placement={placement}
        isOpen={state.isOpen}
        onOpenChange={state.setOpen}
        style={{
          minWidth: inputWidth,
          maxWidth: props.popoverMaxWidth ?? '400px',
        }}
      >
        <ComboBoxOverlay
          {...props}
          onSelectionChange={state.setSelectedKey}
          onClose={state.close}
          selectedKey={state.selectedItem?.key}
          isOpen={state.isOpen}
          label={props.label}
          disabledKeys={props.disabledKeys}
        />
      </ComboBoxPopover>
    </DialogTrigger>
  );
}

export const ComboBox = React.forwardRef(ComboBoxInner) as <T extends object>(
  props: ComboBoxProps<T> & { ref?: React.ForwardedRef<FocusableElement> },
) => ReturnType<typeof ComboBoxInner>;

export { Item as ComboBoxItem, Section as ComboBoxSection } from 'react-stately';
export type { ComboBoxProps };
