import type { DialogProps as AriaDialogProps } from 'react-aria-components';
import type { OverlayTriggerProps, OverlayTriggerState } from 'react-stately';
import React from 'react';
import {
  Dialog as UnstyledDialog,
  Modal as UnstyledModal,
  ModalOverlay as UnstyledModalOverlay,
  OverlayTriggerStateContext,
} from 'react-aria-components';
import { useOverlayTriggerState } from 'react-stately';

import type { IconName } from '../../assets/Icon/Icon';
import type { GutterProp } from '../../common/sizing';
import { fade, palette } from '../../common/colors';
import { sizing } from '../../common/sizing';
import {
  colors,
  darkThemeSelector,
  DIALOG_CONTENT_ZINDEX,
  DIALOG_OVERLAY_ZINDEX,
  keyframes,
  shadows,
  styled,
} from '../../stitches.config';
import { PaneContent, PaneHeader } from '../Pane/Pane';

export type { OverlayTriggerState };

// A hook for creating controlled dialogs
export function useDialogState(props?: OverlayTriggerProps) {
  const state = useOverlayTriggerState(props ?? {});

  // in menus, e.g. a Radix dropdown menu, the menu component will return focus to the trigger
  // when it closes, which cancels out the intended focus behavior when a Dialog opens.
  // To prevent clobbering the focus behavior, we wait until the next tick to open the dialog.
  const openFromMenu = React.useCallback(
    (delay = 50) => {
      setTimeout(state.open, delay);
    },
    [state],
  );

  return { state, openFromMenu };
}

const overlayShow = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const contentShow = keyframes({
  from: { opacity: 0, transform: 'translateY(-2%) scale(.96)' },
  to: { opacity: 1, transform: 'translateY(0) scale(1)' },
});

export const ModalOverlay = styled(UnstyledModalOverlay, {
  position: 'fixed',
  inset: 0,
  zIndex: DIALOG_OVERLAY_ZINDEX,
  backgroundColor: fade(palette.gray900, 0.1),
  backdropFilter: 'blur(5px)',
  boxShadow: `inset 0px 80px 240px 240px ${fade(palette.gray900, 0.12)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [darkThemeSelector]: {
    backgroundColor: fade(palette.gray900, 0.3),
  },
  '&[data-entering]': {
    animation: `${overlayShow} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&[data-exiting]': {
    animation: `${overlayShow} 250ms reverse ease-in`,
  },
});

const Modal = styled(UnstyledModal, {
  willChange: 'transform',

  '&:focus': { outline: 'none' },

  '&[data-entering]': {
    animation: `${contentShow} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&[data-exiting]': {
    animation: `${contentShow} 250ms reverse ease-in`,
  },
});

export type DialogHeaderProps = {
  /**
   * Provide any actions for your pane.
   */
  actions?: React.ReactNode;
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Provide a subheading for your pane.
   */
  subheading?: React.ReactNode;
  /**
   * Provide an icon for your pane.
   */
  icon?: IconName;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: React.ReactNode;
};

export function DialogHeader({
  actions,
  heading,
  subheading,
  icon,
  tabs,
  ...remaining
}: DialogHeaderProps) {
  const state = React.useContext(OverlayTriggerStateContext)!;

  return (
    <PaneHeader
      icon={icon}
      heading={heading}
      tabs={tabs}
      actions={actions}
      onClose={state.close}
      closeButtonAriaLabel="Close dialog"
      {...remaining}
    />
  );
}

export type DialogContentProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
};

const DialogContentContainer = styled(PaneContent, {
  flexBasis: 'auto',
  overflow: 'auto',
});

export function DialogContent({
  children,
  gutter,
  width,
  maxWidth,
  height,
  maxHeight,
}: DialogContentProps) {
  return (
    <DialogContentContainer gutter={gutter} style={{ width, maxWidth, height, maxHeight }}>
      {children}
    </DialogContentContainer>
  );
}

const DialogFooterStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$8',
});

const DialogFooterEnd = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$8',
});

const DialogFooterActions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
});

const DialogFooterContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: sizing.sidesOnly,

  '&::before': {
    position: 'absolute',
    top: '-0.5px',
    left: sizing.sides,
    right: sizing.sides,
    content: '',
    display: 'block',
    height: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '$44',
    borderRadius: '0 0 12px 12px',
  },
});

export type DialogFooterProps = {
  /**
   * Provide any actions for your drawer.
   */
  actions?: React.ReactNode;
};

export function DialogFooter({ actions }: DialogFooterProps) {
  return (
    <DialogFooterContainer>
      <DialogFooterStart />
      <DialogFooterEnd>
        {actions && <DialogFooterActions>{actions}</DialogFooterActions>}
      </DialogFooterEnd>
    </DialogFooterContainer>
  );
}

const DialogContainer = styled(UnstyledDialog, {
  zIndex: DIALOG_CONTENT_ZINDEX,
  vStack: 0,
  alignItems: 'stretch',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.modalLight,
  borderRadius: '12px',
  outline: 'none',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.modalDark,
  },

  '@notMobile': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    width: '90vw',
    maxWidth: '660px',
    maxHeight: '85vh',
    padding: '$8 0',
  },

  '@mobile': {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,

    maxWidth: '90vw !important',
    minWidth: '300px !important',
    maxHeight: '90vh !important',
  },

  '&:focus': { outline: 'none' },

  '& form': {
    // This allows for the use of a `<form>` inside of `DialogContainer`
    // without affecting the flexbox of the children.
    display: 'contents',
  },

  variants: {
    preset: {
      narrow: {
        width: '400px',
        maxWidth: '400px',
        minWidth: '400px',
      },
      intermediate: {
        width: '800px',
        maxWidth: '800px',
        minWidth: '800px',
      },
      wide: {
        width: '1200px',
        maxWidth: '1200px',
        minWidth: '1200px',
      },
    },
  },
});

type DialogProps = AriaDialogProps & {
  /**
   * Select a width preset.
   */
  preset?: 'narrow' | 'intermediate' | 'wide';
  /**
   * Manually set the dialog width.
   */
  width?: string;
  /**
   * Manually set the dialog max width.
   */
  maxWidth?: string;
  /**
   * Manually set the dialog height.
   */
  height?: string;
  /**
   * Manually set the dialog max height.
   */
  maxHeight?: string;
  /**
   * Set whether the dialog can be dismissed by clicking outside of it.
   * @default true
   */
  isDismissable?: boolean;
  /**
   * Pass the `state` object returned from `useDialogState`.
   */
  state: OverlayTriggerState;
};

export function Dialog({
  preset,
  width,
  maxWidth,
  height,
  maxHeight,
  children,
  state,
  ...props
}: DialogProps) {
  return (
    <ModalOverlay isDismissable isOpen={state.isOpen} onOpenChange={state.setOpen}>
      <Modal isDismissable isOpen={state.isOpen} onOpenChange={state.setOpen}>
        <DialogContainer preset={preset} style={{ width, maxWidth, height, maxHeight }} {...props}>
          {children}
        </DialogContainer>
      </Modal>
    </ModalOverlay>
  );
}
