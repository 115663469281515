import type { SVGAttributes } from 'react';
import { OutletIconSVG } from 'atto-svgs';

import { FocusRing } from '../../common/focus_rings';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const OutletNumber = styled('span', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '18px',
  fontFamily: '$mono',
  fontSize: '10px',
  fontWeight: '$bold',
  lineHeight: '12px',
});

const OutletContents = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  height: '100%',
  variants: {
    active: {
      true: {},
      false: {},
    },
    disabled: {
      true: {},
      false: {},
    },
    error: {
      true: {},
      false: {},
    },
    status: {
      connected: {},
      disconnected: {},
    },
    variant: {
      detailed: {
        padding: '5px 0',
      },
      simple: {},
    },
  },
  compoundVariants: [
    {
      disabled: true,
      css: {
        color: colors.hardwareContentDisabledLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentDisabledDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      css: {
        color: colors.hardwareContentPositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentPositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'disconnected',
      css: {
        color: colors.hardwareContentNeutralLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentNeutralDark,
        },
      },
    },
    {
      disabled: false,
      error: true,
      css: {
        color: colors.hardwareContentNegativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentNegativeDark,
        },
      },
    },
  ],
});

const OutletSVG = styled('svg', {
  position: 'absolute',
  zIndex: 0,
  display: 'flex',
  overflow: 'visible',
  paintOrder: 'stroke',
  strokeWidth: '2px',
  color: colors.hardwareBgNeutralLight,
  stroke: colors.hardwareStrokeNeutralLight,

  [darkThemeSelector]: {
    color: colors.hardwareBgNeutralDark,
    stroke: colors.hardwareStrokeNeutralDark,
  },

  variants: {
    active: {
      true: {},
      false: {},
    },
    disabled: {
      true: {},
      false: {},
    },
    error: {
      true: {},
      false: {},
    },
    orientation: {
      top: {},
      right: {},
      bottom: {
        transform: 'rotate(180deg)',
      },
      left: {
        transform: 'rotate(180deg)',
      },
    },
    status: {
      connected: {},
      disconnected: {},
    },
    variant: {
      detailed: {
        width: '$40',
        height: '$36',
        borderRadius: '$6',
      },
      simple: {
        width: '22px',
        height: '$20',
        borderRadius: '5px',
      },
    },
  },
  compoundVariants: [
    {
      orientation: 'right',
      variant: 'detailed',
      css: {
        width: '32px',
      },
    },
    {
      orientation: 'left',
      variant: 'detailed',
      css: {
        width: '32px',
      },
    },
    {
      orientation: 'right',
      variant: 'simple',
      css: {
        width: '18px',
      },
    },
    {
      orientation: 'left',
      variant: 'simple',
      css: {
        width: '18px',
      },
    },
    {
      disabled: true,
      css: {
        color: colors.hardwareBgDisabledLight,
        stroke: colors.hardwareStrokeDisabledLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgDisabledDark,
          stroke: colors.hardwareStrokeDisabledDark,
        },
      },
    },
    {
      error: true,
      css: {
        color: colors.hardwareBgNegativeLight,
        stroke: colors.hardwareStrokeNegativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgNegativeDark,
          stroke: colors.hardwareStrokeNegativeDark,
        },
      },
    },
    {
      disabled: false,
      error: false,
      status: 'connected',
      css: {
        color: colors.hardwareBgPositiveLight,
        stroke: colors.hardwareStrokePositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgPositiveDark,
          stroke: colors.hardwareStrokePositiveDark,
        },
      },
    },
  ],
});

const OutletContainer = styled('div', FocusRing, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$6',
  variants: {
    variant: {
      detailed: {
        width: '$40',
        maxWidth: '$40',
        minWidth: '$40',
        height: '$36',
        maxHeight: '$36',
        minHeight: '$36',
      },
      simple: {
        width: '22px',
        maxWidth: '22px',
        minWidth: '22px',
        height: '$20',
        maxHeight: '$20',
        minHeight: '$20',
      },
    },
  },
});

const OutletIcons = {
  OutletDetailedVertical: OutletIconSVG.OutletDetailedVertical,
  OutletDetailedHorizontal: OutletIconSVG.OutletDetailedHorizontal,
  OutletSimpleVertical: OutletIconSVG.OutletSimpleVertical,
  OutletSimpleHorizontal: OutletIconSVG.OutletSimpleHorizontal,
};

type OutletIconName = keyof typeof OutletIcons;
type OutletPropOrientation = 'top' | 'right' | 'bottom' | 'left';
type OutletPropVariant = 'detailed' | 'simple';

export type OutletProps = {
  active?: boolean;
  disabled?: boolean;
  error?: boolean;
  number?: number;
  onClick?: (event: any) => void;
  orientation?: OutletPropOrientation;
  status?: 'connected' | 'disconnected';
  variant?: OutletPropVariant;
};

const getVariant = (variant: OutletPropVariant) => {
  switch (variant) {
    case 'simple':
      return 'Simple';
    default:
      return 'Detailed';
  }
};

const getOrientation = (orientation: OutletPropOrientation) => {
  switch (orientation) {
    case 'right':
      return 'Vertical';
    case 'bottom':
      return 'Horizontal';
    case 'left':
      return 'Vertical';
    default:
      return 'Horizontal';
  }
};

export function Outlet({
  active = false,
  disabled = false,
  error = false,
  number,
  onClick,
  orientation = 'top',
  status,
  variant = 'detailed',
  ...remaining
}: OutletProps) {
  const outletVariant = `Outlet${getVariant(variant)}${getOrientation(orientation)}`;
  const NamedOutletIcon = OutletIcons[outletVariant as OutletIconName] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedOutletIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${outletVariant}`);
    return null;
  }

  return (
    <OutletContainer
      {...(onClick
        ? {
            role: 'button',
            tabIndex: 0,
            onClick,
          }
        : {})}
      variant={variant}
      {...remaining}
    >
      <OutletContents
        active={active}
        disabled={disabled}
        error={error}
        status={status}
        variant={variant}
      >
        {number && variant === 'simple' && <OutletNumber>{number}</OutletNumber>}
      </OutletContents>
      <OutletSVG
        as={NamedOutletIcon}
        active={active}
        disabled={disabled}
        error={error}
        orientation={orientation}
        status={status}
        variant={variant}
      />
    </OutletContainer>
  );
}
