/* eslint-disable react/button-has-type,react/prop-types */
import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { ControlSize, ControlVariant } from '../shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { LoadingIcon } from '../../assets/LoadingIcon/LoadingIcon';
import { useControlSize } from '../../common/control_size';
import { useControlVariant } from '../../common/control_variant';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { useViewport } from '../../utilities/useViewport';
import { selectors, transitions } from '../shared/styles';
import {
  destructiveDisabledStyles,
  destructiveEnabledStyles,
  iconColor,
  labelColor,
  loadingIconColor,
  primaryDisabledStyles,
  primaryEnabledStyles,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
} from './button_styles';

const StyledIcon = styled(Icon, {
  color: iconColor,
  transition: transitions.control,
  opacity: 1,
  width: '$$iconSize',
  height: '$$iconSize',
  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
    },
  },
});

const StyledLoadingIcon = styled(LoadingIcon, {
  color: loadingIconColor,
  transition: transitions.control,
});

const StyledLabel = styled('span', Body, {
  display: 'flex',
  fontWeight: fontWeights.bold,
  transition: transitions.control,
  truncate: true,
  // NOTE: Override base Text colors
  color: labelColor,
  [darkThemeSelector]: {
    color: labelColor,
  },
  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
    arrangement: {
      'leading-icon': {},
      'leading-label': {},
    },
    condense: {
      true: {},
      false: {},
    },
    labelAlign: {
      start: {
        justifyContent: 'flex-start',
        width: '100%',
      },
      center: {},
      end: {
        justifyContent: 'flex-end',
        width: '100%',
      },
    },
    size: {
      small: {
        lineHeight: '$16',
        fontSize: '$12',
      },
      medium: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      large: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      'x-large': {
        lineHeight: '$24',
        fontSize: '$16',
      },
    },
  },
});

export const BaseButton = styled('button', {
  alignItems: 'center',
  borderRadius: '$8',
  justifyContent: 'space-between',
  cursor: 'pointer',
  display: 'flex',
  opacity: 1,
  position: 'relative',
  transition: transitions.control,
  userSelect: 'none',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  truncate: true,
  whiteSpace: 'nowrap',

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    size: {
      small: {
        $$buttonGap: '$space$4',
        minWidth: '26px',
        minHeight: '$24',
        $$paddingX: '$space$6',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      medium: {
        $$buttonGap: '$space$4',
        minWidth: '$32',
        minHeight: '$28',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
      },
      large: {
        $$buttonGap: '$space$6',
        minWidth: '$40',
        minHeight: '$36',
        $$paddingX: '$space$12',
        $$paddingY: '$space$8',
        $$iconSize: '$space$12',
        borderRadius: '$10',
      },
      'x-large': {
        $$buttonGap: '$space$8',
        minHeight: '$44',
        $$paddingX: '$space$20',
        $$paddingY: '$space$10',
        $$iconSize: '$space$12',
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
    invalid: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      primary: {},
      secondary: {},
    },
  },
  compoundVariants: [
    // Destructive
    {
      variant: 'destructive',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': destructiveEnabledStyles,
        '&': destructiveEnabledStyles,
      },
    },
    {
      variant: 'destructive',
      isDisabled: true,
      css: destructiveDisabledStyles,
    },

    // Primary
    {
      variant: 'primary',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': primaryEnabledStyles,
        '&': primaryEnabledStyles,
      },
    },
    {
      variant: 'primary',
      isDisabled: true,
      css: primaryDisabledStyles,
    },

    // Secondary
    {
      variant: 'secondary',
      isDisabled: false,
      css: secondaryEnabledStyles,
    },
    {
      variant: 'secondary',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },

    {
      isDisabled: false,
      invalid: true,
      css: {
        boxShadow: shadows.fieldErrorLight,
        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },
        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,
          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },
  ],
});

const LoadingIconContainer = styled('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$$buttonGap',
  truncate: true,
  variants: {
    arrangement: {
      'hidden-label': {},
      'leading-icon': {
        flexDirection: 'row-reverse',
      },
      'leading-label': {},
    },
    labelAlign: {
      start: {
        justifyContent: 'flex-start',
        width: '100%',
      },
      center: {},
      end: {
        justifyContent: 'flex-end',
        width: '100%',
      },
    },
  },
});

const DirectionArrow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  flexBasis: 0,
});

const MenuArrow = styled(StyledIcon, {
  marginLeft: 4,
  height: 9,
  width: 9,
});

const PreviousArrow = styled(DirectionArrow, {
  justifyContent: 'flex-start',
  // TRICKY: Since this arrow is always in the DOM, apply a margin to its (only)
  // child so that the arrow takes 0 width when empty.
  '& > *': {
    marginRight: '$$buttonGap',
  },
});

const NextArrow = styled(DirectionArrow, {
  justifyContent: 'flex-end',
  '& > *': {
    marginLeft: '$$buttonGap',
  },
});

export type ButtonArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
export type ButtonMenuArrow = 'dropdown' | 'select';
export type ButtonDirection = 'next' | 'previous';
export type ButtonLabelAlign = 'start' | 'center' | 'end';
export type ButtonSize = ControlSize;
export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonVariant = ControlVariant;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  arrangement?: ButtonArrangement;
  children: React.ReactNode;
  condense?: boolean;
  direction?: ButtonDirection;
  disabled?: boolean;
  invalid?: boolean;
  form?: string;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  menuArrow?: ButtonMenuArrow;
  labelAlign?: ButtonLabelAlign;
  loading?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: ButtonVariant;
  /**
   * Set a width.
   */
  width?: string;
  /**
   * Set a maximum width.
   */
  maxWidth?: string;
  /**
   * Set a minimum width.
   */
  minWidth?: string;
}

function ButtonInner<Tag extends React.ElementType>(
  {
    'aria-label': ariaLabel,
    arrangement = 'leading-label',
    children,
    condense,
    direction,
    disabled = false,
    icon,
    menuArrow,
    labelAlign,
    loading = false,
    invalid = false,
    size,
    width = 'fit-content',
    maxWidth,
    minWidth,
    variant,
    type = 'button',
    ...rest
  }: PolymorphicComponentProps<Tag, ButtonProps>,
  ref: PolymorphicRef<Tag>,
) {
  const { breakpoint } = useViewport();
  const controlSize = useControlSize(size, 'medium');
  const controlVariant = useControlVariant(variant, 'primary');

  // Disable the button during its loading state
  const isDisabled = disabled || loading;

  const hiddenLabel = (condense && breakpoint !== 'desktop') || arrangement === 'hidden-label';

  const buttonRender = (
    <BaseButton
      {...(rest as object)}
      type={type}
      ref={ref}
      aria-label={
        ariaLabel || (arrangement === 'hidden-label' || condense ? `${children}` : undefined)
      }
      variant={controlVariant}
      size={controlSize}
      isDisabled={isDisabled}
      disabled={isDisabled}
      invalid={invalid}
      style={{
        width,
        maxWidth,
        minWidth,
      }}
    >
      {loading && (
        <LoadingIconContainer>
          <StyledLoadingIcon />
        </LoadingIconContainer>
      )}
      <PreviousArrow>
        {direction === 'previous' && <StyledIcon isLoading={loading} icon="arrow-left" />}
      </PreviousArrow>
      <ButtonContent arrangement={arrangement} labelAlign={labelAlign}>
        {!hiddenLabel && (
          <StyledLabel size={controlSize} isLoading={loading} labelAlign={labelAlign}>
            {children}
          </StyledLabel>
        )}
        {icon && <StyledIcon isLoading={loading} icon={icon} />}
      </ButtonContent>
      {menuArrow && (
        <MenuArrow
          isLoading={loading}
          icon={menuArrow === 'dropdown' ? 'chevron-down' : 'arrows-vertical'}
        />
      )}
      <NextArrow>
        {direction === 'next' && <StyledIcon isLoading={loading} icon="arrow-right" />}
      </NextArrow>
    </BaseButton>
  );

  if (arrangement === 'hidden-label' || (condense && breakpoint !== 'desktop')) {
    return (
      <Tooltip contents={children} sideOffset={2}>
        {buttonRender}
      </Tooltip>
    );
  }

  return buttonRender;
}

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => JSX.Element,
): (props: P & React.RefAttributes<T>) => JSX.Element {
  return React.forwardRef(render) as any;
}

export const Button = fixedForwardRef(ButtonInner);
