import React, { type ComponentProps, createContext, useContext } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { GutterProp } from '../../common/sizing';
import type { InternalProp } from '../../utilities/useInternal';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider } from '../../common/control_size';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { Subheading } from '../../text/Subheading';
import { space } from '../../utilities/shared/sizes';
import { VStack } from '../../utilities/Stack/VStack';
import { InternalProvider, useInternal } from '../../utilities/useInternal';
import { Badge } from '../Badge/Badge';
import { Tabs } from '../Tabs/Tabs';

export type SectionPropRelation = 'stacked' | 'standalone';
type SectionRelation = SectionPropRelation;
const SectionRelationContext = createContext<SectionRelation | undefined>(undefined);
export const SectionRelationProvider = SectionRelationContext.Provider;
export const useSectionRelation = (
  controlledValue?: SectionRelation,
  defaultValue: SectionRelation = 'standalone',
) => {
  const sectionRelation = useContext(SectionRelationContext);
  return controlledValue ?? sectionRelation ?? defaultValue;
};

const dividerStyles = {
  content: '',
  position: 'absolute',
  zIndex: 1,
  left: 0,
  right: 0,
  display: 'block',
  height: '1px',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
};

const SectionHeaderLockUp = styled('div', {
  minWidth: 0,
  gridArea: 'start',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100%',
  gap: '$6',
});

const SectionHeaderIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const SectionHeaderHeading = styled(Subheading, {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: colors.headingNeutralLight,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
});

const SectionHeaderDescription = styled(Small);

const SectionHeaderCopy = styled('div', {
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
});

const SectionHeaderActions = styled('div', {
  hStack: '$8',
});

const SectionHeaderTabs = styled(Tabs, {
  minHeight: 'auto',
});

const SectionHeaderStart = styled('div', {
  minWidth: 0,
  position: 'relative',
  zIndex: 1,
  hStack: '$8',
  padding: '$6 0',
});

const SectionHeaderEnd = styled('div', {
  minWidth: 0,
  position: 'relative',
  zIndex: 1,
  hStack: '$8',
});

const SectionHeaderContainer = styled('div', {
  minWidth: 0,
  position: 'relative',
  hStack: '$8',
  justifyContent: 'space-between',
  minHeight: '$36',

  '&:after': {
    ...dividerStyles,
    bottom: '-0.5px',
  },

  variants: {
    relation: {
      stacked: {
        padding: sizing.sidesOnly,

        '&:after': {
          left: sizing.sides,
          right: sizing.sides,
        },
      },
      standalone: {
        padding: sizing.contentSidesOnly,

        '&:after': {
          left: sizing.contentSides,
          right: sizing.contentSides,
        },
      },
    },
  },
});

export type SectionHeaderProps = {
  /**
   * Provide any actions.
   */
  actions?: React.ReactNode;
  /**
   * Provide a description for your pane.
   */
  description?: React.ReactNode;
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Provide an icon for your pane.
   */
  icon?: IconName;
  internal?: InternalProp;
  relation?: SectionPropRelation;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: React.ReactNode;
  style?: React.CSSProperties;
};

export function SectionHeader({
  actions,
  description,
  heading,
  icon,
  internal,
  relation,
  tabs,
  ...remaining
}: SectionHeaderProps) {
  const isInternal = useInternal(internal);
  const sectionRelation = useSectionRelation(relation, 'standalone');
  const hasLockup = icon || heading;
  return (
    <SectionRelationProvider value={sectionRelation}>
      <InternalProvider value={isInternal}>
        <SectionHeaderContainer relation={sectionRelation} {...remaining}>
          <SectionHeaderStart>
            {hasLockup && (
              <SectionHeaderLockUp>
                {icon && <SectionHeaderIcon icon={icon} size={space(14)} />}
                {(heading || isInternal) && (
                  <SectionHeaderCopy>
                    <SectionHeaderLockUp>
                      {heading && <SectionHeaderHeading>{heading}</SectionHeaderHeading>}
                      {isInternal && (
                        <Badge internal ends="card" size="small">
                          Internal
                        </Badge>
                      )}
                    </SectionHeaderLockUp>
                    <SectionHeaderDescription>{description}</SectionHeaderDescription>
                  </SectionHeaderCopy>
                )}
              </SectionHeaderLockUp>
            )}
            {tabs && <SectionHeaderTabs>{tabs}</SectionHeaderTabs>}
          </SectionHeaderStart>
          <SectionHeaderEnd>
            {actions && (
              <ControlSizeProvider value="small">
                <SectionHeaderActions>{actions}</SectionHeaderActions>
              </ControlSizeProvider>
            )}
          </SectionHeaderEnd>
        </SectionHeaderContainer>
      </InternalProvider>
    </SectionRelationProvider>
  );
}

const SectionContentContainer = styled(VStack, {
  position: 'relative',

  '&not(:first-child)::before': {
    ...dividerStyles,
    top: '-0.5px',
  },

  variants: {
    gutter: {
      all: {},
      vertical: {},
      horizontal: {},
      none: {},
    },
    relation: {
      stacked: {
        '&:before': {
          left: sizing.sides,
          right: sizing.sides,
        },
      },
      standalone: {
        '&:before': {
          left: sizing.contentSides,
          right: sizing.contentSides,
        },
      },
    },
  },

  compoundVariants: [
    {
      gutter: 'all',
      relation: 'stacked',
      css: {
        padding: sizing.squish,
      },
    },
    {
      gutter: 'vertical',
      relation: 'stacked',
      css: {
        padding: sizing.endsOnly,
      },
    },
    {
      gutter: 'horizontal',
      relation: 'stacked',
      css: {
        padding: sizing.sidesOnly,
      },
    },
    {
      gutter: 'top',
      relation: 'stacked',
      css: {
        paddingTop: sizing.ends,
      },
    },
    {
      gutter: 'right',
      relation: 'stacked',
      css: {
        paddingRight: sizing.sides,
      },
    },
    {
      gutter: 'bottom',
      relation: 'stacked',
      css: {
        paddingBottom: sizing.ends,
      },
    },
    {
      gutter: 'left',
      relation: 'stacked',
      css: {
        paddingLeft: sizing.sides,
      },
    },
    {
      gutter: 'all',
      relation: 'standalone',
      css: {
        padding: sizing.contentSquish,
      },
    },
    {
      gutter: 'vertical',
      relation: 'standalone',
      css: {
        padding: sizing.contentEndsOnly,
      },
    },
    {
      gutter: 'horizontal',
      relation: 'standalone',
      css: {
        padding: sizing.contentSidesOnly,
      },
    },
    {
      gutter: 'top',
      relation: 'standalone',
      css: {
        paddingTop: sizing.contentEnds,
      },
    },
    {
      gutter: 'right',
      relation: 'standalone',
      css: {
        paddingRight: sizing.contentSides,
      },
    },
    {
      gutter: 'bottom',
      relation: 'standalone',
      css: {
        paddingBottom: sizing.contentEnds,
      },
    },
    {
      gutter: 'left',
      relation: 'standalone',
      css: {
        paddingLeft: sizing.contentSides,
      },
    },
  ],
});

export type SectionContentProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  relation?: SectionPropRelation;
} & ComponentProps<typeof SectionContentContainer>;

export function SectionContent({
  children,
  gutter = 'none',
  relation,
  ...remaining
}: SectionContentProps) {
  const sectionRelation = useSectionRelation(relation, 'standalone');
  return (
    <SectionRelationProvider value={sectionRelation}>
      <SectionContentContainer gutter={gutter} relation={sectionRelation} {...remaining}>
        {children}
      </SectionContentContainer>
    </SectionRelationProvider>
  );
}

const SectionFooterHelper = styled(Small);

const SectionFooterEnds = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
  padding: '$6 0',
});

const SectionFooterStart = styled(SectionFooterEnds);

const SectionFooterEnd = styled(SectionFooterEnds);

const SectionFooterContainer = styled('div', {
  position: 'relative',
  hStack: '$8',
  justifyContent: 'space-between',
  minHeight: '$36',

  '&:before': {
    ...dividerStyles,
    top: '-0.5px',
  },

  variants: {
    relation: {
      stacked: {
        padding: sizing.sidesOnly,

        '&:before': {
          left: sizing.sides,
          right: sizing.sides,
        },
      },
      standalone: {
        padding: sizing.contentSidesOnly,

        '&:before': {
          left: sizing.contentSides,
          right: sizing.contentSides,
        },
      },
    },
  },
});

export type SectionFooterProps = {
  /**
   * Provide any actions for the right-side.
   */
  endActions?: React.ReactNode;
  /**
   * Any helper text that should be shown.
   */
  helper?: React.ReactNode;
  relation?: SectionPropRelation;
  /**
   * Provide any actions for the left-side.
   */
  startActions?: React.ReactNode;
};

export function SectionFooter({
  endActions,
  helper,
  relation,
  startActions,
  ...remaining
}: SectionFooterProps) {
  const sectionRelation = useSectionRelation(relation, 'standalone');
  return (
    <SectionRelationProvider value={sectionRelation}>
      <SectionFooterContainer relation={sectionRelation} {...remaining}>
        <SectionFooterStart>
          {startActions && (
            <ControlGroup size="small" relation="separate">
              {startActions}
            </ControlGroup>
          )}
          {helper && <SectionFooterHelper>{helper}</SectionFooterHelper>}
        </SectionFooterStart>
        <SectionFooterEnd>
          {endActions && (
            <ControlGroup size="small" relation="separate">
              {endActions}
            </ControlGroup>
          )}
        </SectionFooterEnd>
      </SectionFooterContainer>
    </SectionRelationProvider>
  );
}

export const SectionTarget = styled('a', Body, FocusRing, {
  position: 'relative',
  hStack: '$4',
  justifyContent: 'center',
  minHeight: '$36',
  color: colors.blue600,
  cursor: 'pointer',
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.blue300,
  },

  [selectors.hover]: {
    color: colors.blue700,

    [darkThemeSelector]: {
      color: colors.blue200,
    },
  },

  '&:first-child': {
    borderRadiusTop: '$8',
  },

  '&:last-child': {
    borderRadiusBottom: '$8',
  },

  '&:not(:first-child):before': {
    ...dividerStyles,
    top: '-0.5px',
  },

  '&:not(:last-child):after': {
    ...dividerStyles,
    bottom: '-0.5px',
  },
});

const SectionContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  vStack: '$0',
  alignItems: 'stretch',
  width: 'full',

  variants: {
    relation: {
      stacked: {},
      standalone: {
        strokeAll: colors.strokeNeutralLight,
        borderRadius: '$8',

        [darkThemeSelector]: {
          strokeAll: colors.strokeNeutralDark,
        },
      },
    },
  },
});

export type SectionProps = {
  children?: React.ReactNode;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  relation?: SectionPropRelation;
} & ComponentProps<typeof SectionContainer>;

export function Section({
  children,
  internal,
  relation = 'standalone',
  ...remaining
}: SectionProps) {
  return (
    <SectionContainer relation={relation} {...remaining}>
      <InternalProvider value={internal}>
        <SectionRelationProvider value={relation}>{children}</SectionRelationProvider>
      </InternalProvider>
    </SectionContainer>
  );
}
